import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const SET_ACTIVE_MEASUREMENT_SYSTEM = '@measurementSystem/SET_ACTIVE_MEASUREMENT_SYSTEM'

export const SET_ACTIVE_MEASUREMENT_SYSTEM_REQUEST = createRequestTypes(
  'SET_ACTIVE_MEASUREMENT_SYSTEM_REQUEST'
)

export const constants = {
  SET_ACTIVE_MEASUREMENT_SYSTEM,

  SET_ACTIVE_MEASUREMENT_SYSTEM_REQUEST
}

export const setActiveMeasurementSystem = createAction(SET_ACTIVE_MEASUREMENT_SYSTEM)

export const setActiveMeasurementSystemRequest = createRequestAction(
  SET_ACTIVE_MEASUREMENT_SYSTEM_REQUEST
)

export default {
  setActiveMeasurementSystem,

  setActiveMeasurementSystemRequest
}
