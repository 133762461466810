// -------------- External imports ---------------- //
import { useState } from 'react'
import PropTypes from 'prop-types'

// -------------- Material-UI imports ---------------- //
import { Modal, Button, Paper } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'

// -------------- Internal imports ---------------- //
import styles from './ConfirmationModal.module.scss'

const ConfirmationModal = props => {
  const {
    hideModal,
    translate,
    contentText,
    contentDetail,
    title,
    buttonName,
    buttonStyleBlue,
    groupName = '',
    deviceName = ''
  } = props

  const [open] = useState(true)

  const handleConfirmClick = () => {
    props.onConfirm()
  }

  let titleName, buttonText

  if (title) {
    titleName = title
  } else {
    titleName = translate('warning')
  }

  if (buttonName) {
    buttonText = buttonName
  } else {
    buttonText = translate('agree')
  }

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      border: '2px solid #dddddd',
      verticalAlign: 'center !important',
      borderRadius: '32px',
      backgroundColor: '#ffffff',
      display: 'flex'
    }
  })(props => <Paper {...props} />)

  return (
    <Modal
      onClose={hideModal}
      open={open}
      fullWidth
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      scroll='body'
      BackdropProps={{
        classes: {
          root: styles.backdrop
        }
      }}
      classes={{
        root: styles.root
      }}
      PaperComponent={StyledPaper}
    >
      <div className={styles.flexModalContainer}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>
            {titleName}
            <IconButton
              disableRipple
              aria-label='close'
              className={styles.titleIconClose}
              onClick={hideModal}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.content}>
            {groupName !== '' && deviceName !== '' && (
              <div className={styles.contentText}>
                {contentText}
                <b>{' ' + deviceName + ' '}</b>
                {translate('deleteSystemFromGroupConfirmationText.p2')}
                <b>{' ' + groupName + ' '}</b>
                {translate('deleteSystemFromGroupConfirmationText.p3')}
              </div>
            )}
            {groupName === '' && deviceName !== '' && (
              <div className={styles.contentText}>
                {contentText}
                <b>{' ' + deviceName + ' '}</b>
                {translate('deleteSystemConfirmationText.p2')}
              </div>
            )}
            {groupName !== '' && deviceName === '' && (
              <div className={styles.contentText}>
                {contentText}
                <b>{' ' + groupName}</b>
                {translate('deleteGroupConfirmationText.p2')}
              </div>
            )}
            {groupName === '' && deviceName === '' && (
              <div className={styles.contentText}>
                {contentText}
                <div className={styles.contentDetail}>
                  {contentDetail && <b>{contentDetail}</b>}
                </div>
              </div>
            )}
            <Button
              onClick={hideModal}
              className={styles.buttonCancel}
            >
              {translate('cancel')}
            </Button>
            <Button
              disableRipple
              onClick={handleConfirmClick}
              className={buttonStyleBlue ? styles.buttonConfirmBlue : styles.buttonConfirm}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

export default ConfirmationModal
