// --------------- External imports --------------- //
import { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

// --------------- Material-UI imports --------------- //
import { Modal } from '@material-ui/core'

// --------------- Internal imports --------------- //
import AddParameterAlertModalForm from '../components/AddParameterAlertModalForm'
import { getUser } from 'common/selectors/user'
import { getError, getIsSubmitting, getIsAddingEmail } from '../selectors'
import { createAlarm } from 'common/actions/systems'
import { resetError } from 'common/actions/error'
import { hideModal } from 'common/actions/modal'
import { addAlertRecipient, deleteAlertRecipient } from 'common/actions/user'
import ConfirmationModal from 'modules/modals/ConfirmationModal'
import styles from './AddParameterAlertModalContainer.module.scss'
import DataTypes from 'constants/DataTypes'
import * as DataUtils from 'utils/data'

const AddParameterAlertModalContainer = props => {
  const [open] = useState(true)
  const [confirmingDeleteEmail, setConfirmingDeleteEmail] = useState(null)
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const { hideModal, createAlarm, user, resetError, systems, deleteAlertRecipient, translate } =
    props
  const systemsByGroup = _.groupBy(systems, 'group')

  const getNormalizedValuesForType = (value, type) => {
    const { measurementUnits } = user.toJS()
    if (type === 'tempLow' || type === 'tempHigh') type = DataTypes.TEMPERATURE
    let measurementUnit = measurementUnits ? measurementUnits[type] : undefined
    if (type === DataTypes.FORMALDEHYDE) {
      return Number(DataUtils.normalizeFormaldehyde(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.VOCS) {
      return Number(DataUtils.normalizeTvoc(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.VOCS_INDEX) {
      return Number(DataUtils.normalizeTvocIndex(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.O3) {
      return Number(DataUtils.normalizeO3(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.CO) {
      return Number(DataUtils.normalizeCO(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.NO2) {
      return Number(DataUtils.normalizeNo2(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.TEMPERATURE) {
      return Number(DataUtils.normalizeTemperature(value, measurementUnit)).toFixed(1)
    } else {
      return value
    }
  }

  const handleSubmit = async data => {
    const typesSelected = data.types.filter(x => !!x)
    const emailsSelected = data.emailRecipients
    const jsonData = {}
    const jsonDataEmail = {}
    const Meta = {}
    const emails = []
    for (let i = 0; i < typesSelected.length; i++) {
      Meta[typesSelected[i]] = getNormalizedValuesForType(
        data.typesInput[typesSelected[i]],
        typesSelected[i]
      )
    }
    for (let i = 0; i < emailsSelected.length; i++) {
      emails[i] = emailsSelected[i].toLowerCase()
    }
    const names = []
    for (let i = 0; i < data.selectedDevices.length; i++) {
      const system = systems.filter(s => {
        return s._id === data.selectedDevices[i]
      })[0]
      names.push(system.name)
    }

    jsonData.Meta = Meta
    jsonData.alarmName = data.alarmName
    jsonData.names = names
    jsonData.DeviceIds = data.selectedDevices
    jsonData.emailRecipients = emails
    jsonData.CustomerId = user.toJS()._id
    jsonData.active = true
    jsonData.alarmMailHours = {}
    jsonData.alarmMailHours.period1 = data.period1
    if (data.period2) {
      jsonData.alarmMailHours.period2 = data.period2
    }
    if (data.auxPeriod) {
      jsonData.alarmMailHours.auxPeriod = data.auxPeriod
      jsonData.alarmMailHours.auxDays = data.auxDays
    }
    if (data.auxPeriod2) {
      jsonData.alarmMailHours.auxPeriod2 = data.auxPeriod2
    }
    jsonData.alarmMailHours.offset = data.offset
    jsonData.alarmMailHours.days = data.occupancyDays
    jsonDataEmail.email = data.emailForAdd.toLowerCase()
    jsonDataEmail.userId = user.toJS()._id
    createAlarm(jsonData)
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  const handleDeleteAlertRecipient = email => {
    setConfirmingDeleteEmail(email)
    setConfirmingDelete(true)
  }

  const confirmDeleteAlertRecipient = () => {
    deleteAlertRecipient({
      email: confirmingDeleteEmail,
      userId: user.toJS()._id
    })
    setConfirmingDelete(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <AddParameterAlertModalForm
            {...props}
            systems={systems}
            systemsByGroup={systemsByGroup}
            hideModal={hideModal}
            onSubmit={handleSubmit}
            onDeleteAlertRecipient={handleDeleteAlertRecipient}
          />
          {confirmingDelete && (
            <ConfirmationModal
              hideModal={() => setConfirmingDelete(false)}
              translate={translate}
              onConfirm={confirmDeleteAlertRecipient}
              contentText={translate('deleteAlertRecipientConfirmationText')}
              contentDetail={confirmingDeleteEmail}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

AddParameterAlertModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state),
  isAddingEmail: getIsAddingEmail(state)
})

const mapActionsToProps = {
  createAlarm,
  addAlertRecipient,
  deleteAlertRecipient,
  resetError,
  hideModal
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(AddParameterAlertModalContainer)
)
