export const sortGroupsOptions = [
  'sortOptions.groupsAZ',
  'sortOptions.groupsZA',
  'sortOptions.withLessMicas',
  'sortOptions.withMoreMicas'
]
export const sortGroupsMenuOptions = [
  'sortMenuOptions.groupsByName',
  'sortMenuOptions.groupsBySize'
]

export const sortDevicesOptions = [
  'sortOptions.devicesAZ',
  'sortOptions.devicesZA',
  'sortOptions.bestMeasuresFirst',
  'sortOptions.worstMeasuresFirst',
  'sortOptions.lastAdded',
  'sortOptions.firstAdded'
]
export const sortDevicesMenuOptions = [
  'sortMenuOptions.devicesByName',
  'sortMenuOptions.devicesByState',
  'sortMenuOptions.devicesByAddDate'
]

export const alternateDevicesMap = {
  [sortDevicesOptions[0]]: sortDevicesOptions[1],
  [sortDevicesOptions[1]]: sortDevicesOptions[0],
  [sortDevicesOptions[2]]: sortDevicesOptions[3],
  [sortDevicesOptions[3]]: sortDevicesOptions[2],
  [sortDevicesOptions[4]]: sortDevicesOptions[5],
  [sortDevicesOptions[5]]: sortDevicesOptions[4]
}
export const alternateGroupsMap = {
  [sortGroupsOptions[0]]: sortGroupsOptions[1],
  [sortGroupsOptions[1]]: sortGroupsOptions[0],
  [sortGroupsOptions[2]]: sortGroupsOptions[3],
  [sortGroupsOptions[3]]: sortGroupsOptions[2]
}

export const sortGroupOptionsMap = {
  [sortGroupsMenuOptions[0]]: sortGroupsOptions[0],
  [sortGroupsMenuOptions[1]]: sortGroupsOptions[2]
}
export const sortDeviceOptionsMap = {
  [sortDevicesMenuOptions[0]]: sortDevicesOptions[0],
  [sortDevicesMenuOptions[1]]: sortDevicesOptions[2],
  [sortDevicesMenuOptions[2]]: sortDevicesOptions[4]
}

export const gropusOptionTextMap = {
  [sortGroupsOptions[0]]: sortGroupsMenuOptions[0],
  [sortGroupsOptions[1]]: sortGroupsMenuOptions[0],
  [sortGroupsOptions[2]]: sortGroupsMenuOptions[1],
  [sortGroupsOptions[3]]: sortGroupsMenuOptions[1]
}
export const devicesOptionTextMap = {
  [sortDevicesOptions[0]]: sortDevicesMenuOptions[0],
  [sortDevicesOptions[1]]: sortDevicesMenuOptions[0],
  [sortDevicesOptions[2]]: sortDevicesMenuOptions[1],
  [sortDevicesOptions[3]]: sortDevicesMenuOptions[1],
  [sortDevicesOptions[4]]: sortDevicesMenuOptions[2],
  [sortDevicesOptions[5]]: sortDevicesMenuOptions[2]
}
export const isReverseDevicesOrder = selectedOrder => {
  const selectedOrderIndex = sortDevicesOptions.indexOf(selectedOrder)
  return selectedOrderIndex % 2 !== 0 // Returns true if index is odd, false if even
}
export const isReverseGroupssOrder = selectedOrder => {
  const selectedOrderIndex = sortGroupsOptions.indexOf(selectedOrder)
  return selectedOrderIndex % 2 !== 0 // Returns true if index is odd, false if even
}
