import { createAction } from 'utils'

export const SHOW_MODAL = '@modal/SHOW_MODAL'
export const HIDE_MODAL = '@modal/HIDE_MODAL'

export const constants = {
  SHOW_MODAL,
  HIDE_MODAL
}

export const showModal = createAction(SHOW_MODAL)
export const hideModal = createAction(HIDE_MODAL)

export default {
  showModal,
  hideModal
}
