import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

import { makeStyles, StylesProvider } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { blue } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Typography from '@material-ui/core/Typography'

import styles from './EditGroupForm.module.scss'
import IconButton from '@material-ui/core/IconButton'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { LoadingButton } from '@mui/lab'
import { orderSystemsGroupType } from '../../../../../common/functions/orderSystems'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const EditGroupForm = props => {
  const classes = useStyles()

  const {
    submitError,
    translate,
    onSubmit,
    editingGroup,
    systems,
    onCancel,
    onDeleteGroupDevice,
    isSubmitting
  } = props

  const [open, setOpen] = useState({})
  const [groupOpen, setGroupOpen] = useState({})
  const [filterExpression, setFilterExpression] = useState('')
  const [groupName, setGroupName] = useState(editingGroup)
  const [systemsByGroup, setSystemsByGroup] = useState([])
  const [noGroupSystems, setNoGroupSystems] = useState([])
  const [selectedSystems, setSelectedSystems] = useState([])
  const { handleSubmit, register, errors, getValues, setError, clearErrors } = useForm()

  const filteredSystems = systems.filter(system =>
    system.name.toLowerCase().includes(filterExpression.toLowerCase())
  )

  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const handleGroupClick = key => {
    const openKey = groupOpen[key]
    setGroupOpen({ ...groupOpen, [key]: !openKey })
  }

  const orderSystemsByGroup = () => {
    const grouped = orderSystemsGroupType(filteredSystems)
    setNoGroupSystems(grouped.undefined)
    delete grouped.undefined
    setSystemsByGroup(grouped)
  }

  const setDevicesError = devicesSelected => {
    if (devicesSelected) {
      clearErrors('selectedDevices')
      return
    }
    setError('selectedDevices', { shouldFocus: 'selectedDevices' })
  }

  const updateSelectedSystems = systemId => {
    const index = selectedSystems.findIndex(selectedId => selectedId === systemId)
    if (index === -1) {
      setSelectedSystems(current => [...current, systemId])
      setDevicesError(true)
    } else {
      const array = selectedSystems
      array.splice(index, 1)
      setSelectedSystems(array)
      if (array.length > 0) {
        setDevicesError(true)
      } else {
        setDevicesError(false)
      }
    }
  }

  const isChecked = systemId => {
    return selectedSystems.some(selectedId => selectedId === systemId)
  }

  const checkGroupName = groupName => {
    if (groupName === '' || groupName === undefined) {
      setError('groupName', { shouldFocus: 'groupName' })
    } else {
      clearErrors('groupName')
    }
  }
  const editGroupName = groupName => {
    setGroupName(groupName)
  }

  const getGroupData = (group, groupName) => {
    if (group === undefined) {
      return null
    }
    return Object.keys(group).map(type => {
      const typeId = `${groupName}-${type}`
      return (
        <div
          key={typeId}
          className={styles.typeRowWrapper}
        >
          <ListItem
            button
            disableTouchRipple
            className={styles.typeListItem}
          >
            {!open[typeId] ? (
              <ExpandLess
                onClick={() => handleClick(typeId)}
                className={styles.expandIcon}
              />
            ) : (
              <ExpandMore
                onClick={() => handleClick(typeId)}
                className={styles.expandIcon}
              />
            )}
            <ListItemText
              primary={type}
              className={styles.micaTypeItemText}
            />
          </ListItem>
          <Collapse
            in={!open[typeId]}
            timeout='auto'
            unmountOnExit
          >
            {group[type].map((system, index) => {
              return (
                <div
                  key={('systemUpdated', index)}
                  className={styles.micaItem}
                >
                  <FormControlLabel
                    className={styles.checkboxForm}
                    label={system.name}
                    control={
                      <Checkbox
                        className={cx(styles.checkbox)}
                        classes={{ checked: styles.checkedBox }}
                        onChange={() => updateSelectedSystems(system._id)}
                        outline='true'
                        inputRef={register()}
                        disableRipple
                        name={`selectedDevices[${system._id}]`}
                        value={system._id}
                        checked={isChecked(system._id)}
                      />
                    }
                  />
                </div>
              )
            })}
          </Collapse>
        </div>
      )
    })
  }

  const submitFormData = () => {
    const values = getValues({ nest: true })
    checkGroupName(values.groupName)
    if (errors.selectedDevices) delete errors.selectedDevices
    if (_.isEmpty(errors)) {
      const data = {
        group: values.groupName,
        deviceIds: selectedSystems
      }
      if (selectedSystems.length === 0 && values.groupName === editingGroup) {
        onCancel()
      } else {
        onSubmit(data)
      }
    }
  }

  const error = () => {
    if (!submitError) return null

    const message = translate('submitErrors.unknown')

    return (
      <Alert
        severity='error'
        className={styles.error}
        style={{ visibility: submitError ? 'visible' : 'hidden' }}
      >
        <div>{message}</div>
      </Alert>
    )
  }

  useEffect(() => {
    orderSystemsByGroup()
  }, [filterExpression])

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('editGroup')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant='outlined'
              required
              fullWidth
              id='groupName'
              label={translate('groupName')}
              inputRef={register({ required: true })}
              name='groupName'
              value={groupName}
              onChange={event => editGroupName(event.target.value)}
              helperText={`${groupName.length}/36`}
              inputProps={{ maxLength: 36 }}
              className={cx(styles.input, classes.input, {
                [styles.hasError]: errors.name
              })}
            />
            {errors.groupName && (
              <div className={styles.error}>
                <small>{translate('validation.required')}</small>
              </div>
            )}
            <TextField
              variant='outlined'
              fullWidth
              type='search'
              onChange={e => setFilterExpression(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlinedIcon />
                  </InputAdornment>
                )
              }}
              id='searchDevice'
              name='searchDevice'
              placeholder={translate('searchDevice')}
              className={cx(styles.input, classes.input, {
                [styles.hasError]: errors.alarmName
              })}
            />
            <Paper
              className={styles.devicesListPaper}
              classes={{ root: classes.paper }}
            >
              <Typography className={styles.addDevicesHelper}>
                {translate('editGroupForm.groupDevices')}
              </Typography>
              <List
                disableTouchRipple
                className={styles.groupDeviceListPaper}
              >
                {_.map(systemsByGroup[editingGroup], function (systems, group) {
                  systems = _.groupBy(systems, 'micaType')
                  return (
                    <div className={styles.editingGroupWrapper}>
                      {_.map(systems, function (systems, type) {
                        return (
                          <div className={styles.typeRowWrapper}>
                            <ListItem
                              button
                              disableTouchRipple
                              className={styles.typeListItem}
                            >
                              {!groupOpen[type] ? (
                                <ExpandLess
                                  onClick={() => handleGroupClick(type)}
                                  className={styles.expandIcon}
                                />
                              ) : (
                                <ExpandMore
                                  onClick={() => handleGroupClick(type)}
                                  className={styles.expandIcon}
                                />
                              )}
                              <ListItemText
                                primary={type}
                                className={styles.noGroupMicaTypeItemText}
                              />
                            </ListItem>
                            <Collapse
                              in={!groupOpen[type]}
                              timeout='auto'
                              unmountOnExit
                            >
                              {systems.map((system, index) => {
                                return (
                                  <div
                                    key={index + 'systemUpdated'}
                                    className={styles.groupDeviceRow}
                                  >
                                    <Typography className={styles.groupDeviceName}>
                                      {system.name}
                                    </Typography>
                                    <IconButton
                                      onClick={() => onDeleteGroupDevice(system._id, system.name)}
                                      className={styles.groupDeviceIcon}
                                    >
                                      <DeleteOutlineIcon />
                                    </IconButton>
                                  </div>
                                )
                              })}
                            </Collapse>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </List>
            </Paper>
            <Typography className={styles.addDevicesHelper}>
              {translate('editGroupForm.selectDevices')}
            </Typography>
            <Paper
              className={styles.devicesListPaper}
              classes={{ root: classes.paper }}
            >
              <List
                disableTouchRipple
                className={styles.devicesList}
              >
                <div className={styles.noGroupWrapper}>
                  {getGroupData(noGroupSystems, '')}
                  {Object.keys(systemsByGroup).map(group => {
                    if (group === editingGroup) return null
                    return (
                      <div key={group}>
                        <ListItem
                          button
                          disableTouchRipple
                          className={styles.groupListItem}
                        >
                          {!open[group] ? (
                            <ExpandLess
                              onClick={() => handleClick(group)}
                              className={styles.expandIcon}
                            />
                          ) : (
                            <ExpandMore
                              onClick={() => handleClick(group)}
                              className={styles.expandIcon}
                            />
                          )}
                          <ListItemText
                            primary={group}
                            className={styles.listItemText}
                          />
                        </ListItem>
                        <Collapse
                          in={!open[group]}
                          timeout='auto'
                          unmountOnExit
                          classes={{
                            wrapper: classes.wrapper
                          }}
                        >
                          {getGroupData(systemsByGroup[group], group)}
                        </Collapse>
                      </div>
                    )
                  })}
                </div>
              </List>
            </Paper>

            {error()}

            <div className={styles.buttonWrapper}>
              <LoadingButton
                fullWidth
                disableRipple
                variant='contained'
                className={styles.button}
                loading={isSubmitting}
                disabled={isSubmitting}
                loadingPosition='end'
                onClick={submitFormData}
              >
                {translate('saveChanges')}
              </LoadingButton>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                disableRipple
                variant='contained'
                className={styles.buttonCancelar}
                onClick={onCancel}
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {translate('cancel')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

EditGroupForm.propTypes = {
  submitError: PropTypes.any,
  translate: PropTypes.func.isRequired
}

export default withLocalize(EditGroupForm)
