import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const FORGOTTEN_PASSWORD = '@password/FORGOTTEN_PASSWORD'
export const RECOVER_PASSWORD = '@password/RECOVER_PASSWORD'

export const FORGOTTEN_PASSWORD_REQUEST = createRequestTypes('FORGOTTEN_PASSWORD_REQUEST')
export const RECOVER_PASSWORD_REQUEST = createRequestTypes('RECOVER_PASSWORD_REQUEST')

export const constants = {
  FORGOTTEN_PASSWORD,
  RECOVER_PASSWORD,

  FORGOTTEN_PASSWORD_REQUEST,
  RECOVER_PASSWORD_REQUEST
}

export const forgottenPassword = createAction(FORGOTTEN_PASSWORD)
export const recoverPassword = createAction(RECOVER_PASSWORD)

export const forgottenPasswordRequest = createRequestAction(FORGOTTEN_PASSWORD_REQUEST)
export const recoverPasswordRequest = createRequestAction(RECOVER_PASSWORD_REQUEST)

export default {
  forgottenPassword,
  recoverPassword,

  forgottenPasswordRequest,
  recoverPasswordRequest
}
