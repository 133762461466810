import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import { initialize, setActiveLanguage } from 'react-localize-redux'
import moment from 'moment'

import actions, { constants } from '../actions/localize'
import snackbarActions from 'common/actions/snackbar'

import { getUserId } from 'common/selectors/user'

import Languages from 'constants/Languages'
import Translations from 'constants/Translations'

import * as api from 'api/language'
import * as LocalizeStore from 'utils/localizeStore'

export function* onInitLocalize() {
  yield put(
    initialize({
      languages: Languages,
      translation: Translations,
      options: {
        renderToStaticMarkup: false
      }
    })
  )

  const data = LocalizeStore.getLocalizeStoreData()
  const lang = data ? data.lang : Languages[0].code // default spanish
  yield put(setActiveLanguage(lang))
  yield call(moment.locale, lang)

  if (data) {
    yield put(setActiveLanguage(data.lang))
  } else {
    yield put(setActiveLanguage(Languages[0].code)) // spanish
  }
}

export function* onSetActiveLanguage({ payload: lang }) {
  const userId = yield select(getUserId)

  // If no user id means no user is logged in
  // In that case, just set the language in the local storage
  // And reload the window
  if (!userId) {
    yield call(LocalizeStore.setAuthStoreData, lang)
    window.location.reload()
  }
  // Otherwise
  // Request set language, on success:
  // Set language in local storage and reload the window
  yield put(actions.setActiveLanguageRequest.start())

  try {
    yield call(api.setLanguage, userId, lang)
    yield put(actions.setActiveLanguageRequest.success())
    yield call(LocalizeStore.setAuthStoreData, lang)
    window.location.reload()
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })

    yield put(actions.setActiveLanguageRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export default function* watchLocalize() {
  yield all([
    takeLatest(constants.INIT_LOCALIZE, onInitLocalize),
    takeLatest(constants.SET_ACTIVE_LANGUAGE, onSetActiveLanguage)
  ])
}
