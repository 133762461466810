import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import _ from 'lodash'

import { Modal } from '@material-ui/core'

import { requestFile } from 'utils/request'
import ReportModalForm from '../components/AddReportModalForm'
import styles from './AddReportModalContainer.module.scss'

const AddReportModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, systems, userId, user } = props

  const systemsByGroup = _.groupBy(systems, 'group')
  const [error, setError] = useState('')
  const getDayWithSuffix = day => {
    if (day > 3 && day < 21) return `${day}th` // Handle 11th, 12th, 13th
    switch (day % 10) {
      case 1:
        return `${day}st`
      case 2:
        return `${day}nd`
      case 3:
        return `${day}rd`
      default:
        return `${day}th`
    }
  }

  const formatDateWithSuffix = (date, locale, options) => {
    const formattedDate = date.toLocaleDateString(locale, options)
    const [month, day, year] = formattedDate.split(' ')
    const dayWithSuffix = getDayWithSuffix(parseInt(day))
    return `${month} ${dayWithSuffix}, ${year}`
  }
  const handleSubmit = async data => {
    if (props.isSubmitting) return

    const startDate = new Date(data.startDate.toString())

    const endDate = new Date(data.endDate.toString())
    const now = new Date()
    const submitData = {
      systemId: data.deviceId,
      startDate,
      endDate,
      startDateFormatted:
        user.toJS().language === 'es'
          ? data.startDate
              .toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })
              .replace(/\sde\s/g, ' ')
          : formatDateWithSuffix(data.startDate, 'en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            }),
      endDateFormatted:
        user.toJS().language === 'es'
          ? data.endDate
              .toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })
              .replace(/\sde\s/g, ' ')
          : formatDateWithSuffix(data.endDate, 'en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            }),
      startHour: data.firstOcupationStart,
      endHour: data.firstOcupationEnd,
      todaysDate:
        user.toJS().language === 'es'
          ? now
              .toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })
              .replace(/\sde\s/g, ' ')
          : formatDateWithSuffix(now, 'en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            }),
      name: data.name,
      location: data.location,
      occupationDays: data.occupationDays,
      params: data.params,
      language: user.toJS().language,
      customerId: userId,
      micasType: data.micasType,
      micasName: data.micasName,
      period1: data.period1
    }
    if (data.period2) submitData.period2 = data.period2
    if (data.auxPeriod) submitData.auxPeriod = data.auxPeriod
    if (data.auxDays) submitData.auxDays = data.auxDays
    if (data.auxPeriod2) submitData.auxPeriod2 = data.auxPeriod2

    if (data.secondOcupationStart) submitData.secondOcupationStart = data.secondOcupationStart
    if (data.secondOcupationEnd) submitData.secondOcupationEnd = data.secondOcupationEnd

    await requestFile(`reports/`, {
      method: 'POST',
      data: {
        ...submitData
      }
    }).then(async response => {
      const worked = response.headers.get('reult')

      if (worked === 'worked') {
        const fileUrl = response.headers.get('fileUrl')

        response.blob().then(blob => {
          // Setting various property values
          const alink = document.createElement('a')
          alink.href = fileUrl
          alink.download = submitData.name + '.pdf'
          alink.click()
        })

        hideModal()
      } else if (worked === 'there is no data') {
        setError('statusCode:409')
      } else {
        setError('statusCode:405')
      }
    })
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <ReportModalForm
          {...props}
          onSubmit={handleSubmit}
          systems={systems}
          systemsByGroup={systemsByGroup}
          error={error}
        />
      </div>
    </Modal>
  )
}

AddReportModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  title: PropTypes.string,
  contentText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  buttonName: PropTypes.string
}

export default withLocalize(AddReportModalContainer)
