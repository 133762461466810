import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const GET_FULLSCREEN_BY_ID = '@fullscreen/GET_FULLSCREEN_BY_ID'
export const GET_FULLSCREEN_USER = '@fullscreen/GET_FULLSCREEN_USER'
export const POLL_FULLSCREEN_START = '@fullscreen/POLL_FULLSCREEN_START'
export const POLL_FULLSCREEN_STOP = '@fullscreen/POLL_FULLSCREEN_STOP'
export const POLL_CHANNEL_START = '@fullscreen/POLL_CHANNEL_START'
export const POLL_CHANNEL_STOP = '@fullscreen/POLL_CHANNEL_STOP'

export const GET_FULLSCREEN_BY_ID_REQUEST = createRequestTypes(
  '@fullscreen/GET_FULLSCREEN_BY_ID_REQUEST'
)
export const GET_FULLSCREEN_USER_REQUEST = createRequestTypes(
  '@fullscreen/GET_FULLSCREEN_USER_REQUEST'
)
export const POLL_FULLSCREEN_REQUEST = createRequestTypes('@fullscreen/POLL_FULLSCREEN_REQUEST')

export const POLL_CHANNEL_REQUEST = createRequestTypes('@fullscreen/POLL_CHANNEL_REQUEST')

export const constants = {
  GET_FULLSCREEN_BY_ID,
  GET_FULLSCREEN_USER,
  POLL_FULLSCREEN_START,
  POLL_FULLSCREEN_STOP,
  POLL_CHANNEL_START,
  POLL_CHANNEL_STOP,

  GET_FULLSCREEN_BY_ID_REQUEST,
  GET_FULLSCREEN_USER_REQUEST,
  POLL_FULLSCREEN_REQUEST,
  POLL_CHANNEL_REQUEST
}

export const getFullScreenById = createAction(GET_FULLSCREEN_BY_ID)
export const getFullScreenUser = createAction(GET_FULLSCREEN_USER)
export const pollFullScreenStart = createAction(POLL_FULLSCREEN_START)
export const pollFullScreenStop = createAction(POLL_FULLSCREEN_STOP)
export const pollChannelStart = createAction(POLL_CHANNEL_START)
export const pollChannelStop = createAction(POLL_CHANNEL_STOP)

export const getFullScreenByIdRequest = createRequestAction(GET_FULLSCREEN_BY_ID_REQUEST)
export const getFullScreenUserRequest = createRequestAction(GET_FULLSCREEN_USER_REQUEST)
export const pollFullScreenRequest = createRequestAction(POLL_FULLSCREEN_REQUEST)
export const pollChannelRequest = createRequestAction(POLL_CHANNEL_REQUEST)

export default {
  getFullScreenById,
  getFullScreenUser,
  pollFullScreenStart,
  pollFullScreenStop,
  pollChannelStart,
  pollChannelStop,

  getFullScreenByIdRequest,
  getFullScreenUserRequest,
  pollFullScreenRequest,
  pollChannelRequest
}
