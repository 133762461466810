const TEMPERATURE = 'temperature'
const HUMIDITY = 'humidity'
const CO2 = 'co2'
const FORMALDEHYDE = 'formaldehyde'
const PRESSURE = 'pressure'
const VOCS = 'vocs'
const VOCS_INDEX = 'vocsIndex'
const PM1 = 'pm1'
const PM4 = 'pm4'
const PM10 = 'pm10'
const PM25 = 'pm25'
const CO = 'co'
const O3 = 'o3'
const RADON = 'radon'
const VENTILATION_MODE = 'ventilationMode'
const TEMPERATURE_CONTROL = 'temperatureControl'
const COVID_19 = 'covid19'
const IAQ = 'iaq'
const VENTILATION_INDICATOR = 'ventilationIndicator'
const PRESSURE_PIP = 'pressurePip'
const OSC_RATE = 'oscRate'
const VOLUME = 'volume'
const EI = 'ei'
const RATE_MIN = 'rateMin'
const P_TRIGGER = 'pTrigger'
const RADIANT_TEMPERATURE = 'radiantTemperature'
const LIGHT = 'lux'
const NOISE = 'dB'
const NO2 = 'no2'
const NOX = 'nox'
const CAPACITY = 'capacity'
const THERMAL_INDICATOR = 'thermalIndicator'

const WEATHER = 'weather'
const OUTDOOR_AIR_QUALITY = 'outdoorAirQuality'

export default {
  TEMPERATURE,
  HUMIDITY,
  CO2,
  FORMALDEHYDE,
  PRESSURE,
  VOCS,
  VOCS_INDEX,
  PM1,
  PM4,
  PM10,
  PM25,
  CO,
  O3,
  RADON,
  VENTILATION_MODE,
  TEMPERATURE_CONTROL,
  COVID_19,
  IAQ,
  VENTILATION_INDICATOR,
  PRESSURE_PIP,
  OSC_RATE,
  VOLUME,
  EI,
  RATE_MIN,
  P_TRIGGER,
  RADIANT_TEMPERATURE,
  LIGHT,
  NOISE,
  NO2,
  NOX,
  CAPACITY,
  THERMAL_INDICATOR,

  WEATHER,
  OUTDOOR_AIR_QUALITY,
  DUST: [PM1, PM10, PM25]
}
