const MICA = 'MICA'
const MICA_LITE = 'MICA_LITE'
const MICA_MINI = 'MICA_MINI'
const MICA_DESK = 'DESK'
const MICA_WELL = 'MICA_WELL'
const MICA_PLUS = 'MICA_PLUS'

export default {
  MICA,
  MICA_LITE,
  MICA_MINI,
  MICA_DESK,
  MICA_WELL,
  MICA_PLUS
}
