import { useEffect, useState } from 'react'
import styles from './UpdateTimer.module.scss'
import cx from 'classnames'

const UpdateTimer = ({ timestamp, translate }) => {
  const [timer, setTime] = useState('')

  useEffect(() => {
    updateTime()
    const interval = setInterval(updateTime, 60000)
    return function cleanup() {
      clearInterval(interval)
    }
  }, [timestamp])

  const updateTime = () => {
    const currTimestamp = new Date().getTime()
    let diff = (currTimestamp - timestamp) / 1000
    if (diff < 60) {
      setTime(`${Math.abs(Math.round(diff))} sec`)
    } else {
      diff /= 60
      if (diff < 60) {
        setTime(`${Math.abs(Math.round(diff))} min`)
      } else if (diff > 60 && diff < 1440) {
        diff /= 60
        setTime(`${Math.abs(Math.round(diff))} h`)
      } else if (diff > 1440 && diff < 43200) {
        diff /= 1440
        setTime(`${Math.abs(Math.round(diff))} days`)
      } else {
        diff /= 43800
        setTime(`${Math.abs(Math.round(diff))} months`)
      }
    }
  }

  return (
    <h6 className={cx(styles.updateText, 'update')}>
      {translate('lastUpdate') + ':'} <b>{timer}</b>
    </h6>
  )
}

export default UpdateTimer
