import AppLayout from 'common/components/layouts/AppLayout'
import AuthLayout from 'common/components/layouts/AuthLayout'
import MainLayout from 'common/components/layouts/MainLayout'
import PublicLayout from 'common/components/layouts/PublicLayout'
import FullScreenLayout from 'common/components/layouts/FullScreenLayout'

import Login from './Login'
import Home from './Home'
import SignUp from './SignUp'
import ConfirmUserInfo from './ConfirmUserInfo'
import ConfirmUser from './ConfirmUser'
import ConfirmRecipient from './ConfirmRecipient'
import ForgottenPassword from './ForgottenPassword'
import RecoverPassword from './RecoverPassword'
import Logout from 'modules/Logout'
import Dashboard from './Dashboard'
import SignUpCollaborator from './SignUpCollaborator'
import Channel from './Channel'
import FullScreenLink from './FullScreenLink'
import FullScreen from './FullScreen'
import Alerts from './Alerts'
import Visualisation from './Visualisation'
import Profile from './Profile'
import NotFound from './NotFound'
import Policy from './PolicyandTerms/containers/Policy'
import Terms from './PolicyandTerms/containers/Terms'
import Cookies from './PolicyandTerms/containers/Cookies'
import ManageCookies from './PolicyandTerms/containers/ManageCookies'
import Reports from './Reports'
import Certifications from './Certifications'
import CheckCollaborator from './CheckCollaborator'
import requireAuth from 'utils/requireAuth'

import * as Routes from 'constants/Routes'
import ConfirmCollaborator from './ConfirmCollaborator'

export default store => {
  return {
    component: AppLayout,
    childRoutes: [
      {
        component: AuthLayout,
        childRoutes: [
          Login(store),
          SignUp(store),
          SignUpCollaborator(store),
          ConfirmUserInfo(store),
          ConfirmUser(store),
          ConfirmRecipient(store),
          ConfirmCollaborator(store),
          CheckCollaborator(store),
          ForgottenPassword(store),
          RecoverPassword(store),
          ManageCookies(store)
        ]
      },
      {
        component: requireAuth(MainLayout),
        path: Routes.MAIN,
        childRoutes: [
          Home(store),
          Dashboard(store),
          Profile(store),
          Visualisation(store),
          FullScreen(store),
          Alerts(store),
          Reports(store),
          Certifications(store)
        ]
      },
      {
        component: PublicLayout,
        childRoutes: [Channel(store), Policy(store), Terms(store), Cookies(store)]
      },
      {
        component: FullScreenLayout,
        childRoutes: [FullScreenLink(store)]
      },
      Logout(store),
      NotFound(store),
      {
        path: '*',
        indexRoute: {
          onEnter: (nextState, replace) => replace(Routes.NOT_FOUND)
        }
      }
    ]
  }
}
