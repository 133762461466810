import { useState } from 'react'
import PropTypes from 'prop-types'

import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import DownloadSettingsForm from '../components/DownloadStatisticsForm'
import { requestFile } from '../../../../utils/request'

import FileSaver from 'file-saver'
import { getAuthStoreData } from '../../../../utils/authStore'
import styles from './DownloadStatisticsContainer.module.scss'

const DownloadStatisticsContainer = props => {
  const [open] = useState(true)

  const { hideModal, user } = props
  const { isPremium, _id } = user.toJS()

  const handleSubmit = async data => {
    if (props.isSubmitting) return

    const systemId = props.system._id
    const startDate = new Date(data.startDate.toString())
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)

    const endDate = new Date(data.endDate.toString())
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    const submitData = {
      params: data.params,
      micaname: props.system.name,
      freq: data.freq,
      order: data.order,
      CustomerId: _id,
      DeviceId: systemId
    }
    const authStoreData = getAuthStoreData()
    await requestFile(
      `systems/statistics/` + startDate.toISOString() + `/` + endDate.toISOString(),
      {
        method: 'POST',
        data: {
          ...submitData
        },
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'content-type': 'application/json',
          Authorization: authStoreData
        }
      }
    ).then(async response => {
      const blob = await response.blob().then(blob => blob)
      const fileName = response.headers.get('filename')
      FileSaver.saveAs(blob, fileName)
    })
    hideModal()
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <DownloadSettingsForm
          {...props}
          isPremium={isPremium}
          onSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}

DownloadStatisticsContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

export default withLocalize(DownloadStatisticsContainer)
