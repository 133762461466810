import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const AUTH_USER = '@auth/AUTH_USER'
export const LOGIN = '@auth/LOGIN'
export const SIGN_UP = '@auth/SIGN_UP'
export const SIGN_UP_COLLABORATOR = '@auth/SIGN_UP_COLLABORATOR'
export const CONFIRM_USER = '@auth/CONFIRM_USER'
export const RESEND_CONFIRMATION_MAIL = '@auth/RESEND_CONFIRMATION_MAIL'
export const LOGOUT = '@auth/LOGOUT'

export const AUTH_USER_REQUEST = createRequestTypes('@auth/AUTH_USER_REQUEST')
export const LOGIN_REQUEST = createRequestTypes('@auth/LOGIN_REQUEST')
export const SIGN_UP_REQUEST = createRequestTypes('@auth/SIGN_UP_REQUEST')
export const SIGN_UP_COLLABORATOR_REQUEST = createRequestTypes('@auth/SIGN_UP_COLLABORATOR_REQUEST')
export const CONFIRM_USER_REQUEST = createRequestTypes('@auth/CONFIRM_USER_REQUEST')
export const RESEND_CONFIRMATION_MAIL_REQUEST = '@auth/RESEND_CONFIRMATION_MAIL_REQUEST'

export const constants = {
  AUTH_USER,
  LOGIN,
  SIGN_UP,
  SIGN_UP_COLLABORATOR,
  CONFIRM_USER,
  RESEND_CONFIRMATION_MAIL,
  LOGOUT,

  AUTH_USER_REQUEST,
  LOGIN_REQUEST,
  SIGN_UP_REQUEST,
  SIGN_UP_COLLABORATOR_REQUEST,
  CONFIRM_USER_REQUEST,
  RESEND_CONFIRMATION_MAIL_REQUEST
}

export const authUser = createAction(AUTH_USER)
export const logout = createAction(LOGOUT)
export const login = createAction(LOGIN)
export const signUp = createAction(SIGN_UP)
export const signUpCollaborator = createAction(SIGN_UP_COLLABORATOR)
export const confirmUser = createAction(CONFIRM_USER)
export const resendConfirmationMail = createAction(RESEND_CONFIRMATION_MAIL)

export const authUserRequest = createRequestAction(AUTH_USER_REQUEST)
export const loginRequest = createRequestAction(LOGIN_REQUEST)
export const signUpRequest = createRequestAction(SIGN_UP_REQUEST)
export const signUpCollaboratorRequest = createRequestAction(SIGN_UP_COLLABORATOR_REQUEST)
export const confirmUserRequest = createRequestAction(CONFIRM_USER_REQUEST)
export const resendConfirmationMailRequest = createRequestAction(RESEND_CONFIRMATION_MAIL_REQUEST)

export default {
  authUser,
  login,
  signUp,
  signUpCollaborator,
  confirmUser,
  logout,
  resendConfirmationMail,

  authUserRequest,
  loginRequest,
  signUpRequest,
  signUpCollaboratorRequest,
  confirmUserRequest,
  resendConfirmationMailRequest
}
