import { createSelector } from 'reselect'
import { getUsers as getUserEntities } from './entities'

export const getUserId = state => state.user.get('user')
export const getIsLoaded = state => state.user.get('isLoaded')
export const getIsLoggedIn = state => state.user.get('isLoggedIn')

export const getUsers = getUserEntities

export const getUser = createSelector([getUserEntities, getUserId], (entities, id) =>
  entities.get(String(id))
)

export const getUserSystems = state => {
  const { systems } = getUser(state).toJS()

  return systems
}

export const getUserLang = state => {
  const user = getUser(state)

  if (!user) return null

  return user.get('language')
}
