import { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import { deleteUserDevice } from 'common/actions/user'
import DeleteDeviceUserModal from '../components/DeleteDeviceUserModalForm'
import { resetError } from 'common/actions/error'
import { hideModal } from 'common/actions/modal'
import styles from './DeleteDeviceUserModalContainer.module.scss'
import { getUser } from 'common/selectors/user'
import { getIsSubmitting } from '../selectors'
import ConfirmationModal from '../../ConfirmationModal'
const DeleteDeviceUserModalContainer = props => {
  const [open] = useState(true)
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [datas, setDatas] = useState({})
  const [tranlatedText, setTranslatedText] = useState()
  const { hideModal, resetError, systems, user, deleteUserDevice, translate } = props
  const systemsByGroup = _.groupBy(systems, 'group')

  let text
  const ConfirDelete = async data => {
    let jsonData = {}

    jsonData.deviceIds = data.selectedDevices
    jsonData.customerId = user.toJS()._id
    text = (
      <p>
        {translate('confirmDeleteFirstText')} <b>{data.selectedDevices.length}</b>{' '}
        {data.selectedDevices.length > 1
          ? translate('confirmDeleteThirdText')
          : translate('confirmDeleteSecondText')}{' '}
        <br /> <br />
        {translate('confirmDeleteFourthText')}{' '}
      </p>
    )
    setTranslatedText(text)
    setDatas(jsonData)
    setConfirmingDelete(true)
  }
  const handleSubmit = () => {
    deleteUserDevice(datas)
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <DeleteDeviceUserModal
            {...props}
            systems={systems}
            systemsByGroup={systemsByGroup}
            hideModal={hideModal}
            onSubmit={ConfirDelete}
          />
          {confirmingDelete && (
            <ConfirmationModal
              hideModal={() => setConfirmingDelete(false)}
              translate={translate}
              onConfirm={handleSubmit}
              contentText={tranlatedText}
              buttonName={translate('cancelButton')}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

DeleteDeviceUserModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  deleteUserDevice: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  deleteUserDevice,
  resetError,
  hideModal
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(DeleteDeviceUserModalContainer)
)
