import { call, put, all, take, takeLatest, fork, cancel, cancelled } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { normalize } from 'normalizr'
import { setActiveLanguage } from 'react-localize-redux'

import actions, { constants } from 'common/actions/fullscreen'
import * as schemas from 'schemas'
import * as api from 'api/fullscreen'
import snackbarActions from 'common/actions/snackbar'
import { setUserMeasurementSystem } from 'utils/auxStore'
import * as LocalizeStore from 'utils/localizeStore'

let pollFullScreenTask = null
let pollChannelTask = null
const POLLING_DELAY = 600000 // 10 minute

function* onGetSystemById({ payload: id }) {
  yield put(actions.getFullScreenByIdRequest.start())
  try {
    const { system } = yield call(api.getFullScreenById, id)
    const norm = yield call(normalize, system, schemas.system)
    yield put(actions.getFullScreenByIdRequest.success(norm))
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })

    yield put(actions.getFullScreenByIdRequest.failure(err))
  }
}

function* onGetFullScreenUser({ payload: id }) {
  yield put(actions.getFullScreenUserRequest.start())
  try {
    const { user } = yield call(api.getFullScreenUser, id)
    setUserMeasurementSystem(user.measurementSystem || 'mS')
    yield put(setActiveLanguage(user.language))
    yield call(LocalizeStore.setAuthStoreData, user.language)
    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.getFullScreenUserRequest.success(norm))
  } catch (err) {
    // eslint-disable-next-line no-console
    // if (__DEV__) console.log({ err })

    yield put(actions.getFullScreenUserRequest.failure(err))
  }
}

function* pollFullScreen(id) {
  try {
    while (true) {
      yield put(actions.pollFullScreenRequest.start())

      try {
        const { system } = yield call(api.getFullScreenById, id)
        const norm = yield call(normalize, system, schemas.system)
        yield put(actions.pollFullScreenRequest.success(norm))

        yield delay(POLLING_DELAY)
      } catch (err) {
        // eslint-disable-next-line no-console
        // if (__DEV__) console.log({ err })

        // Stop polling task when error
        yield put(actions.pollFullScreenRequest.failure(err))
        yield put(snackbarActions.showSnackbar('snackbar.whoops'))
        yield put(actions.pollFullScreenStop())
      }
    }
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-console
      // if (__DEV__) console.log('Polling task stopped')
    }
  }
}

function* watchPollFullScreen() {
  while (true) {
    const { payload: id } = yield take(constants.POLL_FULLSCREEN_START)

    // Start polling task
    pollFullScreenTask = yield fork(pollFullScreen, id)

    // Wait until POLL_CHANNEL_STOP action
    yield take(constants.POLL_FULLSCREEN_STOP)

    // Cancel polling task
    yield cancel(pollFullScreenTask)
  }
}

function* pollChannel(id) {
  try {
    while (true) {
      yield put(actions.pollChannelRequest.start())

      try {
        const { system } = yield call(api.getChannelById, id)
        const norm = yield call(normalize, system, schemas.system)
        yield put(actions.pollChannelRequest.success(norm))

        yield delay(POLLING_DELAY)
      } catch (err) {
        // eslint-disable-next-line no-console
        // if (__DEV__) console.log({ err })

        // Stop polling task when error
        yield put(actions.pollChannelRequest.failure(err))
        yield put(snackbarActions.showSnackbar('snackbar.whoops'))
        yield put(actions.pollChannelStop())
      }
    }
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-console
      // if (__DEV__) console.log('Polling task stopped')
    }
  }
}

function* watchPollChannel() {
  while (true) {
    const { payload: id } = yield take(constants.POLL_CHANNEL_START)

    // Start polling task
    pollChannelTask = yield fork(pollChannel, id)

    // Wait until POLL_CHANNEL_STOP action
    yield take(constants.POLL_CHANNEL_STOP)

    // Cancel polling task
    yield cancel(pollChannelTask)
  }
}

export default function* watchFullScreens() {
  yield fork(watchPollFullScreen)
  yield fork(watchPollChannel)

  yield all([
    takeLatest(constants.GET_FULLSCREEN_BY_ID, onGetSystemById),
    takeLatest(constants.GET_FULLSCREEN_USER, onGetFullScreenUser)
  ])
}
