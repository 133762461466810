import { useState, useEffect } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import * as SystemUtils from 'utils/system'

import { ArrowForward, ArrowBack, TrainOutlined } from '@material-ui/icons'
import { blue } from '@material-ui/core/colors'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  IconButton
} from '@material-ui/core'
import { StylesProvider, makeStyles } from '@material-ui/styles'

import styles from './EditFullScreenViewForm.module.scss'
import DataTypes from 'constants/DataTypes'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-2px)'
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const EditFullScreenViewForm = props => {
  const classes = useStyles()
  const { onSubmit, translate, screen, system, user } = props

  // State variable for changing between form pages
  const [activeTab, setActiveTab] = useState(0)

  // Aux variables for system
  const [systemState, setSystemState] = useState({})

  // Page 1
  const [indicatorSelected, setIndicatorSelected] = useState('')
  const [openInfo, setOpenInfo] = useState({})
  const [infoTab, setInfoTab] = useState('')
  const [screenViewName, setScreenViewName] = useState(screen.name)

  // Page 2
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [typeChecked, setTypeChecked] = useState([])

  // Page 3
  const [checkedSwitch, setCheckedSwitch] = useState(false)
  const [checkedLogoSwitch, setCheckedLogoSwitch] = useState(false)

  const [type, setType] = useState([])
  const [sortedDataState, setSortedDataState] = useState([])
  const typeArray = []
  const { handleSubmit, register, errors, getValues, isSubmitting, setError, clearErrors } =
    useForm()

  const handleChange = event => {
    if (event.target.type === 'checkbox') {
      const { value, checked } = event.target
      setTypeChecked([...typeChecked, value])
      if (!checked) {
        setTypeChecked(typeChecked.filter(item => item !== value))
      }
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams === 0) {
        setError('types', { shouldFocus: 'types' })
      } else if (numberOfParams > 12) {
        setError('tooManyParams', { shouldFocus: 'tooManyParams' })
      } else {
        clearErrors('types')
        clearErrors('tooManyParams')
      }
    }
  }

  const handleChangeIndicator = event => {
    setIndicatorSelected(event.target.value)
  }

  const handleInfo = keyInfo => {
    const openDataType = openInfo[keyInfo]
    setOpenInfo({ ...openInfo, [keyInfo]: !openDataType })
    if (infoTab === keyInfo) {
      setInfoTab('')
    } else {
      setInfoTab(keyInfo)
    }
  }

  const getTypeInfo = dataType => {
    switch (dataType) {
      case DataTypes.COVID_19:
        return 'micaLiteInfo'
      case DataTypes.VENTILATION_INDICATOR:
        return 'micaMiniInfo'
      default:
        return 'micaInfo'
    }
  }

  const getTypeInfoLink = dataType => {
    switch (type) {
      case DataTypes.COVID_19:
        return 'https://inbiot.es/wikinbiot/indicador-resistencia-virus'
      case DataTypes.VENTILATION_INDICATOR:
        return 'https://www.inbiot.es/wikinbiot/indicador-eficacia-ventilacion'
      case DataTypes.THERMAL_INDICATOR:
        return 'https://inbiot.es/wikinbiot/indicadore-confort-termohigrometrico'
      default:
        return 'https://inbiot.es/wikinbiot/indicador-calidad-de-aire-interior'
    }
  }

  const handleSelectAll = event => {
    setIsCheckAll(!isCheckAll)
    setTypeChecked(type)
    if (isCheckAll) {
      setTypeChecked([])
    } else {
      clearErrors('types')
    }
  }

  const handleScreenNameChange = event => {
    setScreenViewName(event.target.value)
    if (event.target.value !== '') {
      clearErrors('screenViewName')
    } else {
      setError('screenViewName', { shouldFocus: 'screenViewName' })
    }
  }

  const handleChangeSwitch = event => {
    setCheckedSwitch(event.target.checked)
  }

  const handleChangeLogoSwitch = event => {
    setCheckedLogoSwitch(event.target.checked)
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const checkInputs = () => {
    if (getValues('screenViewName') !== undefined) {
      typeArray.screenName = getValues('screenViewName')
    }
    type.map((t, i) => {
      typeArray[t] = !!(getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0)
      return null
    })
    typeArray.checkedSwitch = checkedSwitch
    typeArray.showLogo = checkedLogoSwitch
    typeArray.indicator = indicatorSelected
    setSystemState(typeArray)
    clearErrors()
  }

  const onClickNext = () => {
    if (activeTab === 0) {
      if (getValues('screenViewName') === '') {
        setError('screenViewName', { shouldFocus: 'screenViewName' })
      } else {
        setActiveTab(1)
      }
    } else {
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams !== 0 && getValues('screenViewName') !== '') setActiveTab(2)
      if (numberOfParams === 0) setError('types', { shouldFocus: 'types' })
    }
  }

  const onClickBack = () => {
    if (activeTab === 2) {
      clearErrors('types')
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }

  const onClickUpdate = () => {
    checkInputs()
  }
  useEffect(() => {
    const sortedData = SystemUtils.getDataSorted(system[0])
    setSortedDataState(sortedData)
  }, [])
  useEffect(() => {
    const types = []
    sortedDataState.map(d => {
      types.push(d.type)
      return null
    })
    setType(types)
  }, [sortedDataState, systemState])

  useEffect(() => {
    const typesSelected = []

    for (let i = 0; i < Object.keys(screen.Meta).length; i++) {
      if (screen.Meta[Object.keys(screen.Meta)[i]]) {
        typesSelected.push(Object.keys(screen.Meta)[i])
        if (isIndicator(Object.keys(screen.Meta)[i]))
          setIndicatorSelected(Object.keys(screen.Meta)[i])
      }
    }
    setTypeChecked(typesSelected)
    setCheckedSwitch(screen.publicAccess)
    setCheckedLogoSwitch(screen.showLogo)
    setIndicatorSelected(getIndicatorSelected(typesSelected))
  }, [setType, screen.Meta, screen.publicAccess, screen.showLogo])

  const isIndeterminate = () => {
    return isCheckAll && typeChecked.length > 0 && typeChecked.length !== type.length
  }

  const isIndicator = type => {
    return (
      type === DataTypes.IAQ ||
      type === DataTypes.COVID_19 ||
      type === DataTypes.VENTILATION_INDICATOR ||
      type === DataTypes.THERMAL_INDICATOR
    )
  }

  const getIndicatorSelected = typeChecked => {
    if (
      !typeChecked.includes(DataTypes.IAQ) &&
      !typeChecked.includes(DataTypes.COVID_19) &&
      !typeChecked.includes(DataTypes.VENTILATION_INDICATOR) &&
      !typeChecked.includes(DataTypes.THERMAL_INDICATOR)
    )
      return 'none'
    if (typeChecked.includes(DataTypes.IAQ)) return DataTypes.IAQ
    if (typeChecked.includes(DataTypes.COVID_19)) return DataTypes.COVID_19
    if (typeChecked.includes(DataTypes.THERMAL_INDICATOR)) return DataTypes.THERMAL_INDICATOR
    if (typeChecked.includes(DataTypes.VENTILATION_INDICATOR))
      return DataTypes.VENTILATION_INDICATOR
  }

  const checkNumberOfParams = () => {
    let numberOfParams = 0
    type.forEach(typeValue => {
      if (!isIndicator(typeValue)) {
        numberOfParams++
      }
    })
    if (numberOfParams <= 12) {
      return true
    }
    return false
  }

  const moreThan8Params = checkNumberOfParams()

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>
          {translate('editFullScreenModal.editShareable')}
        </Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(systemState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div className={styles.nav}>
                <Typography className={styles.subheaderSelected}>
                  {translate('editFullScreenModal.navGeneral')}
                </Typography>
                <Typography className={styles.subheader}>
                  {translate('editFullScreenModal.navParams')}
                </Typography>
                <Typography className={styles.subheader}>
                  {translate('editFullScreenModal.navPrivacy')}
                </Typography>
              </div>
              <TextField
                variant='outlined'
                required
                fullWidth
                onChange={handleScreenNameChange}
                label={translate('editFullScreenModal.screenViewName')}
                id='screenViewName'
                name='screenViewName'
                error={errors.screenViewName}
                value={screenViewName}
                helperText={`${screenViewName.length}/36`}
                placeholder={translate('editFullScreenModal.screenViewName')}
                inputRef={register({ required: TrainOutlined })}
                inputProps={{ maxLength: 36 }}
                className={cx(styles.input, classes.input, {
                  [styles.hasError]: errors.screenViewName
                })}
              />
              {errors.screenViewName && (
                <div className={styles.error}>
                  <small>{translate('validation.required')}</small>
                </div>
              )}
              <div>
                <Typography className={styles.subInfo}>
                  {translate('addFullScreenModal.selectIndicator')}
                </Typography>
              </div>
              <FormControl component='fieldset'>
                <RadioGroup
                  className={styles.checkboxForm}
                  aria-label='indicator'
                  name='indicator'
                  value={indicatorSelected}
                  onChange={handleChangeIndicator}
                >
                  {type.map((dataType, i) => {
                    if (!isIndicator(dataType)) return null
                    return (
                      <div
                        key={i + 'indicator'}
                        className={styles.wrap}
                      >
                        <div className={styles.wrapperInfo}>
                          <FormControlLabel
                            className={styles.labelRadio}
                            value={dataType}
                            control={
                              <Radio
                                name='indicator'
                                inputRef={register()}
                                className={cx(styles.checkbox, styles.radioItem)}
                                classes={{ checked: styles.checkedBox }}
                              />
                            }
                            label={translate(dataType)}
                          />
                          <div className={styles.wrapperInfoButton}>
                            <IconButton
                              className={styles.infoButton}
                              variant='outlined'
                              // onClick={setOpenInfo(dataType)}
                              onClick={() => handleInfo(dataType)}
                              value={getValues(`types[${i}]`)}
                              name='infoParam'
                              disableRipple
                            >
                              <InfoOutlinedIcon />
                            </IconButton>
                          </div>
                        </div>
                        <div
                          style={{
                            display: infoTab === dataType ? 'block' : 'none'
                          }}
                          className={styles.caja}
                        >
                          {
                            <Translate
                              id={getTypeInfo(dataType)}
                              options={{ renderInnerHtml: true }}
                            />
                          }
                          {dataType === DataTypes.COVID_19 && (
                            <Button
                              fullWidth
                              disableRipple
                              variant='contained'
                              className={styles.buttonSiguiente}
                              onClick={e => {
                                e.preventDefault()
                                window.open(getTypeInfoLink(dataType), '_blank')
                              }}
                            >
                              <a
                                href={getTypeInfoLink(dataType)}
                                target='_blank'
                                rel='noopener noreferrer'
                              />
                              {translate('infoModal.moreInfo')}
                            </Button>
                          )}
                        </div>
                      </div>
                    )
                  })}
                  <div className={styles.wrap}>
                    <div className={styles.wrapperInfo}>
                      <FormControlLabel
                        className={styles.labelRadio}
                        value='none'
                        control={
                          <Radio
                            name='indicator'
                            inputRef={register()}
                            className={cx(styles.checkbox, styles.radioItem)}
                            classes={{ checked: styles.checkedBox }}
                          />
                        }
                        label={translate('addFullScreenModal.noIndicator')}
                      />
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
              <div className={styles.buttonWrapper}>
                <Button
                  // type='submit'
                  fullWidth
                  variant='contained'
                  className={styles.buttonSiguiente}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={onClickNext}
                >
                  {translate('next')}
                  <ArrowForward className={styles.arrow} />
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonCancelar}
                  onClick={onClickCancel}
                >
                  {translate('cancel')}
                </Button>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div className={styles.nav}>
                <Typography className={styles.subheader}>
                  {translate('editFullScreenModal.navGeneral')}
                </Typography>
                <Typography className={styles.subheaderSelected}>
                  {translate('editFullScreenModal.navParams')}
                </Typography>
                <Typography className={styles.subheader}>
                  {translate('editFullScreenModal.navPrivacy')}
                </Typography>
              </div>
              <div>
                <Typography className={styles.subInfo}>
                  {translate('editFullScreenModal.selectParameters')}
                </Typography>
              </div>
              {moreThan8Params && (
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAll'
                      id='selectAll'
                      indeterminate={isIndeterminate()}
                      onChange={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  }
                  label={translate('addFullScreenModal.selectAll')}
                />
              )}
              <div className={styles.checkboxWrapper}>
                {type.map((dataType, i) => {
                  if (isIndicator(dataType)) return null
                  return (
                    <div
                      key={i + 'param'}
                      className={styles.checkboxFormDiv}
                    >
                      <FormControlLabel
                        className={styles.checkboxForm}
                        control={
                          <Checkbox
                            defaultChecked={screen.Meta[dataType]}
                            className={cx(styles.checkbox)}
                            classes={{ checked: styles.checkedBox }}
                            onChange={handleChange}
                            checked={typeChecked.includes(dataType)}
                            required
                            name={`types[${i}]`}
                            value={dataType}
                            inputRef={register()}
                            disableRipple
                          />
                        }
                        label={translate(dataType)}
                      />
                    </div>
                  )
                })}
              </div>
              {errors.types && (
                <div className={styles.error}>
                  <small>{translate('validation.anyFieldRequired')}</small>
                </div>
              )}
              {errors.tooManyParams && (
                <div className={styles.error}>
                  <small>{translate('validation.tooManyParams')}</small>
                </div>
              )}
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  disabled={errors.tooManyParams || errors.types || isSubmitting}
                  className={styles.buttonSiguiente}
                  isLoading={isSubmitting}
                  onClick={onClickNext}
                >
                  {translate('next')}
                  <ArrowForward className={styles.arrow} />
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonCancelar}
                  onClick={onClickBack}
                >
                  <ArrowBack className={styles.arrow} />
                  {translate('back')}
                </Button>
              </div>
            </div>
            <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
              <div className={styles.nav}>
                <Typography className={styles.subheader}>
                  {translate('editFullScreenModal.navGeneral')}
                </Typography>
                <Typography className={styles.subheader}>
                  {translate('editFullScreenModal.navParams')}
                </Typography>
                <Typography className={styles.subheaderSelected}>
                  {translate('editFullScreenModal.navPrivacy')}
                </Typography>
              </div>
              <div className={styles.visualizeQRWrapper}>
                <Typography className={styles.visualizeQR}>
                  {translate('editFullScreenModal.infoLabelRadio')}
                </Typography>
                <Switch
                  checked={checkedSwitch}
                  className={classes.switch}
                  onChange={handleChangeSwitch}
                  name='checked'
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div className={styles.infoPublicWrapper}>
                <div className={styles.iconPublic}>
                  <InfoOutlinedIcon />
                </div>
                <Typography>{translate('editFullScreenModal.infoPublic')}</Typography>
              </div>
              <div className={styles.visualizeLogoWrapper}>
                <Typography className={styles.visualizeLogo}>
                  {translate('editFullScreenModal.infoShowLogo')}
                </Typography>
                <Switch
                  checked={checkedLogoSwitch}
                  disabled={!user.toJS().isPremium}
                  onChange={handleChangeLogoSwitch}
                  name='checkedLogo'
                  inputProps={{ 'aria-label': 'controlled' }}
                  className={classes.switch}
                />
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  className={styles.buttonSiguiente}
                  // disabled={isSubmitting}
                  // isLoading={isSubmitting}
                  onClick={onClickUpdate}
                >
                  {translate('editFullScreenModal.saveChanges')}
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonCancelar}
                  // disabled={isSubmitting}
                  // isLoading={isSubmitting}
                  onClick={onClickBack}
                >
                  <ArrowBack className={styles.arrow} />
                  {translate('back')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

export default withLocalize(connect()(EditFullScreenViewForm))
