import { useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { useForm } from 'react-hook-form'
import _ from 'lodash'
import Checkbox from '@mui/material/Checkbox'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import {
  TextField,
  Typography,
  Button,
  InputAdornment,
  ListItem,
  FormControlLabel,
  Collapse,
  ListItemText,
  IconButton
} from '@material-ui/core'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { blue } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import styles from './TranferSystemModalForm.module.scss'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { LoadingButton } from '@mui/lab'
const TranferSystemModalForm = props => {
  const { systems, onSubmit, translate, isSubmitting, systemsByGroup, submitError } = props
  const error = () => {
    if (!submitError) return null

    let message = translate('submitErrors.unknown')
    if (submitError.res && submitError.res.status === 401) {
      message = translate('submitErrors.unknownEmail')
    }

    return (
      <Alert
        severity='error'
        className={styles.errorMessage}
      >
        {message}
      </Alert>
    )
  }
  const useStyles = makeStyles(() => ({
    input: {
      [`& fieldset`]: {
        borderRadius: 30,
        borderColor: 'grey'
      },
      '& label.Mui-focused': {
        color: blue[600]
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'grey'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'grey'
        },
        '&:hover fieldset': {
          borderColor: 'grey'
        },
        '&.Mui-focused fieldset': {
          borderColor: blue[600]
        }
      }
    },
    switch: {
      '& .Mui-checked': {
        color: blue[600]
      },
      '& .MuiSwitch-colorSecondary': {
        color: 'rgba(255,255,255)'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: 'rgba(0,155,229,1)'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: blue[600],
        color: blue[600]
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
        color: blue[600]
      },
      '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
        backgroundColor: blue[100]
      },
      '& .MuiSwitch-thumb': {
        transform: 'translateY(-3px)',
        width: '21px',
        height: '21px'
      },
      '& .MuiSwitch-track': {
        backgroundColor: 'rgba(33,33,33, 0.4)'
      }
    },
    paper: {
      border: 'none',
      boxShadow: 'none'
    },
    wrapper: {
      width: '100%'
    }
  }))
  const classes = useStyles()
  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const onClickNext = () => {
    const values = getValues()

    if (activeTab === 0) {
      if (values && values.selectedDevices !== undefined && values.selectedDevices.length > 0) {
        setActiveTab(1)
      } else {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }
    }
  }

  const apply = () => {
    if (!emailNameField) {
      setError('emailError', { shouldFocus: 'emailError' })
    } else {
      const data = {}

      const values = getValues()

      if (values?.selectedDevices !== undefined && !Array.isArray(values.selectedDevices)) {
        values.selectedDevices = [values.selectedDevices]
      }
      data.selectedDevices = values.selectedDevices
      data.email = emailNameField

      onSubmit(data)
    }
  }

  const onClickBack = () => {
    setActiveTab(0)
  }

  const [systemsGroupState, setSystemsGroupState] = useState(systemsByGroup)
  const { handleSubmit, register, errors, clearErrors, getValues, setError } = useForm()
  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [open, setOpen] = useState({})
  const [activeTab, setActiveTab] = useState(0)
  const [emailNameField, setEmailNameField] = useState('')

  const handleChangeAddEmail = event => {
    setEmailNameField(event.target.value)
    if (event.target.value !== '') {
      clearErrors('emailError')
    }
  }

  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }

  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
      clearErrors('selectedDevices')
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }

  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setSelectedDevices([])
    } else {
      const systemIds = []
      systems.map(s => {
        systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }

  const handleSelectAllGroupDevices = (event, systems) => {
    if (event.target.checked) {
      const aux = []
      for (let i = 0; i < systems.length; i++) {
        if (!selectedDevices.includes(systems[i]._id)) aux.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
      clearErrors('selectedDevices')
    }
  }

  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('transferDevice')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('navAccount')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={filterExpressionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('searchDevice')}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.alarmName
                  })}
                />
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAllDevices'
                      id='selectAllDevices'
                      indeterminate={isIndeterminateDevices()}
                      onChange={handleSelectAllDevices}
                      checked={isCheckAllDevices}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <Paper
                  className={styles.devicesListPaper}
                  classes={{ root: classes.paper }}
                >
                  <List
                    disableTouchRipple
                    className={styles.devicesList}
                  >
                    {_.map(systemsGroupState, function (systems, group) {
                      const systemsType = _.groupBy(systems, 'micaType')
                      return (
                        <div className={styles.noGroupWrapper}>
                          {group !== 'undefined' && (
                            <div>
                              <ListItem
                                button
                                disableTouchRipple
                                className={styles.groupListItem}
                              >
                                {!open[group] ? (
                                  <ExpandLess
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                ) : (
                                  <ExpandMore
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                )}
                                <Checkbox
                                  className={cx(styles.checkbox)}
                                  classes={{ checked: styles.checkedBox }}
                                  name={group}
                                  onChange={e => handleSelectAllGroupDevices(e, systems)}
                                  id={group}
                                />
                                <ListItemText
                                  primary={group}
                                  className={styles.listItemText}
                                />
                              </ListItem>
                            </div>
                          )}
                          {group === 'undefined' && (
                            <div>
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div className={styles.typeRowWrapper}>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={type}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={system._id}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </div>
                          )}

                          {group !== 'undefined' && (
                            <Collapse
                              in={!open[group]}
                              timeout='auto'
                              unmountOnExit
                              classes={{
                                wrapper: classes.wrapper
                              }}
                            >
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div className={styles.typeRowWrapper}>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={translate(type)}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={index + 'systemsType'}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </Collapse>
                          )}
                        </div>
                      )
                    })}
                  </List>
                </Paper>
                <div className={styles.wrapperError}>
                  {errors.selectedDevices && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyDeviceRequired')}</small>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div className={styles.emailModelHeight}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderParams}>
                    {translate('navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('navAccount')}
                  </Typography>
                </div>
                <div className={styles.emailContent}>
                  <div className={styles.useAccount}>
                    <p className={styles.emailText}>{translate('indicateEmail')}</p>
                  </div>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    id='emailForAdd'
                    onChange={handleChangeAddEmail}
                    label={translate('alertsPage.addEmail')}
                    onKeyPress={e => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    inputRef={register()}
                    name='emailForAdd'
                    autoComplete='emailForAdd'
                    className={cx(styles.input, classes.input, {
                      [styles.hasError]: errors.emailError
                    })}
                  />
                  {errors.emailError && (
                    <div className={styles.error}>
                      <small>{translate('validation.required')}</small>
                    </div>
                  )}
                  <div className={styles.infoIcon}>
                    <IconButton
                      className={styles.infoButton}
                      variant='outlined'
                      value={' '}
                      name='infoParam'
                      disableRipple
                    >
                      <InfoOutlinedIcon className={styles.infoSize} />
                    </IconButton>
                    <p className={styles.emailUser}>{translate('emailUser')}</p>
                  </div>
                  <div className={styles.wrapperError}>
                    {errors.emailForAdd && (
                      <div className={styles.error}>
                        <small>{translate('validation.inValidEmail')}</small>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.buttonWrapper}>
                  {error()}
                  <LoadingButton
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    onClick={apply}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    loadingPosition='end'
                  >
                    {translate('apply')}
                  </LoadingButton>

                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

export default withLocalize(TranferSystemModalForm)
