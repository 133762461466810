import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import FullScreenView from '../components/FullScreenView/FullScreenView'
import { getSystemByIdRaw } from 'common/selectors/systems'

const FullScreenSystem = props => {
  const { system, screen, hideModal, translate, user } = props
  const params = []
  for (let i = 0; i < Object.keys(screen.Meta).length; i++) {
    if (screen.Meta[Object.keys(screen.Meta)[i]]) {
      params.push(Object.keys(screen.Meta)[i])
    }
  }
  const handleClose = () => {
    hideModal()
  }

  const systemJS = system.toJS()

  return (
    <FullScreenView
      user={user}
      system={systemJS}
      data={systemJS.data.filter(data => params.includes(data.type))}
      onClose={handleClose}
      translate={translate}
      screen={screen}
    />
  )
}

const mapStateToProps = (state, ownProps) => ({
  system: getSystemByIdRaw(ownProps.screen.DeviceId)(state)
})

const mapActionsToProps = {}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(FullScreenSystem))
