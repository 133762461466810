import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
// Material-UI imports
import { IconButton, Modal } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { hideModal } from 'common/actions/modal'

// Imports from own project
import styles from './ConfirmTransferModalContainer.module.scss'

import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as TransferDevice } from '../../../../icons/transfer.svg'
import { LoadingButton } from '@mui/lab'
import { confirmTransferDevice } from 'common/actions/user'
import { getIsSubmitting } from '../selectors'
const ConfirmTransferModalContainer = props => {
  const { _id, hideModal, translate, isSubmitting, confirmTransferDevice, transferDevice } = props

  const [open] = useState(true)

  const confirmTransfer = () => {
    let data = {}
    data.customerId = _id
    data.condition = true
    data.deviceIds = transferDevice.deviceIds
    confirmTransferDevice(data)
  }
  const refuseTransfer = () => {
    let data = {}
    data.customerId = _id
    data.condition = false
    confirmTransferDevice(data)
  }
  const handleUnderstood = () => {
    hideModal()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.centerModal}>
          <div className={styles.modalContainer}>
            <div className={styles.mainPageWrapper}>
              <IconButton
                onClick={() => handleUnderstood()}
                className={styles.titleIconClose}
              >
                <CloseIcon className={styles.iconClose} />
              </IconButton>
              <div className={styles.titleWrapper}>
                <TransferDevice className={styles.logoImage} />
              </div>
              <h1 className={styles.title}>
                {/* {isPremium
                ? translate('whatIsNew.newFunctions')
                : translate('whatIsNew.nonPremiumNewFunctions')} */}
                {translate('transfertDeviceTitle')}
              </h1>
              <Typography className={styles.mainDescription}>
                {/* {isPremium
                ? translate('whatIsNew.discoverWhatIsNew')
                : translate('whatIsNew.discoverWhatIsNewNonPremium')} */}
                {translate('transdferDeviceFirstText')}
                <b>{transferDevice.email}</b>
                {translate('transdferDeviceSecondText')}
                <b>{transferDevice.deviceIds.length}</b>
                {transferDevice.deviceIds.length > 1
                  ? translate('transdferDeviceFourthText')
                  : translate('transdferDeviceThirdText')}
              </Typography>
              <div className={styles.spaces}>
                <LoadingButton
                  className={styles.refuseButton}
                  onClick={refuseTransfer}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  loadingPosition='end'
                >
                  {translate('refuseTransfer')}
                </LoadingButton>
                <LoadingButton
                  className={styles.acceptButton}
                  onClick={confirmTransfer}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  loadingPosition='end'
                >
                  {translate('acceptTransfer')}
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

ConfirmTransferModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  confirmTransferDevice: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  confirmTransferDevice,
  hideModal
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(ConfirmTransferModalContainer)
)
