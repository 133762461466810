import { SupportButton } from 'icons'
import styles from './AlternativeSupport.module.scss'
import { useState } from 'react'
export const AlternativeSupport = ({ translate }) => {
  const [supportBoxVisible, setSupportBoxVisible] = useState(false)
  return (
    <div className={styles.componentContainer}>
      {supportBoxVisible && (
        <div className={styles.supportBox}>
          <div className={styles.boxHeader}>
            <span>{translate('beacon.responses')}</span>
          </div>
          <div className={styles.boxContentContainer}>
            <div className={styles.boxContent}>
              <div className={styles.contentHeader}>
                <span className={styles.headerTitle}>{translate('beacon.header.title')}</span>
                <span className={styles.headerSubtitle}>{translate('beacon.header.subtitle')}</span>
              </div>
              <div className={styles.contentBody}>
                <span className={styles.bodyTitle}>{translate('beacon.body.title')}</span>
                <span className={styles.bodySubtitle}>{translate('beacon.body.subtitle')}</span>
                <ol className={styles.bodyList}>
                  <li className={styles.listText}>
                    <span>{translate('beacon.body.list.p1')}</span>
                  </li>
                  <li className={styles.listText}>
                    <span>{translate('beacon.body.list.p2')}</span>
                  </li>
                </ol>
                <span className={styles.bodyFooter}>{translate('beacon.body.footer')}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={styles.supportButtonContainer}
        onClick={() => setSupportBoxVisible(!supportBoxVisible)}
      >
        <img
          className={styles.supportButton}
          src={SupportButton}
        />
      </div>
    </div>
  )
}
