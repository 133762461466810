import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { getUser } from 'common/selectors/user'
import { hideModal } from '../../../../common/actions/modal'
import {
  updateSystemGlobalCalibration,
  updateSystemCalibration
} from '../../../../common/actions/systems'
import { getError, getIsSubmitting } from '../selectors'
import { resetError } from 'common/actions/error'
import styles from './EditSystemCalibrationCo2Container.module.scss'
import EditSystemCalibrationCo2 from '../components/EditSystemCalibrationCo2Form/EditSystemCalibrationCo2Form'

const EditSystemCalibrationCo2Container = props => {
  const [open] = useState(true)

  const {
    hideModal,
    system,
    updateSystemGlobalCalibration,
    updateSystemCalibration,
    resetError,
    user
  } = props

  const handleSubmit = async data => {
    if (data.selectAll) {
      updateSystemGlobalCalibration({
        CustomerId: user.toJS()._id,
        ventilationType: data.autocalibrationco2
      })
    } else {
      updateSystemCalibration({
        _id: system._id,
        ventilationType: data.autocalibrationco2
      })
    }
  }

  const initialVentilationType = system.ventilationType

  useEffect(() => {
    resetError()
  }, [resetError])

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <EditSystemCalibrationCo2
          {...props}
          initialVentilationType={initialVentilationType}
          hideModal={hideModal}
          onSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  updateSystemCalibration,
  updateSystemGlobalCalibration,
  hideModal,
  resetError
}

EditSystemCalibrationCo2Container.propTypes = {
  hideModal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapActionsToProps)(EditSystemCalibrationCo2Container)
