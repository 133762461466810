import PropTypes from 'prop-types'
import styles from './FullScreenLayout.module.scss'

import AppLayout from '../AppLayout'

const FullScreenLayout = props => {
  return (
    <div className={styles.component}>
      <div className={styles.content}>{props.children}</div>
    </div>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node
}

export default FullScreenLayout
