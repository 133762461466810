import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

import { Button, Typography } from '@material-ui/core'
import styles from './ShowMessageForm.module.scss'

const DownloadMultipleSettingsForm = props => {
  const { translate, title, text } = props

  const onClickCancel = () => {
    props.hideModal()
  }

  return (
    <div className={styles.modalContainer}>
      <Typography className={styles.header}>{title}</Typography>
      <div className={styles.content}>
        <span className={styles.textContent}>{text.content}</span>
        <span className={styles.textFooter}>{text.footer}</span>
      </div>

      <div className={styles.footer}>
        <Button
          fullWidth
          variant='contained'
          className={styles.buttonCancelar}
          onClick={onClickCancel}
        >
          {translate('close')}
        </Button>
      </div>
    </div>
  )
}

DownloadMultipleSettingsForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(DownloadMultipleSettingsForm)
