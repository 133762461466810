import { AUTH_STORE_NAMESPACE } from '../config'
import * as storage from './storage'

export const setAuthStoreData = token => {
  storage.setItem(AUTH_STORE_NAMESPACE, token)
}

export const getAuthStoreData = () => {
  const data = storage.getItem(AUTH_STORE_NAMESPACE)

  if (!data) {
    return false
  }

  return data
}

export const clearAuthStoreData = () => {
  storage.removeItem(AUTH_STORE_NAMESPACE)
}
