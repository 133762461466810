import { hideSnackbar, SHOW_SNACKBAR, SHOW_ZIPCODE_SNACKBAR } from 'common/actions/snackbar'

export default () => dispatch => action => {
  if (action.type === SHOW_SNACKBAR) {
    setTimeout(() => {
      dispatch(hideSnackbar())
    }, 4000)
  }
  if (action.type === SHOW_ZIPCODE_SNACKBAR) {
    setTimeout(() => {
      dispatch(hideSnackbar())
    }, 8000)
  }

  dispatch(action)
}
