import {
  Cloud,
  Cloudy,
  Moon,
  PartlyCloudyNight,
  PartlyCloudy,
  RainScatteredNight,
  RainScattered,
  Rain,
  Sun,
  Thunderstorm,
  WindySrong,
  WinterWarning
} from 'icons'
export const getWeatherIcon = weatherIcon => {
  switch (weatherIcon) {
    case '01d':
      return Sun
    case '01n':
      return Moon
    case '02d':
      return PartlyCloudy
    case '02n':
      return PartlyCloudyNight
    case '03d' || '03n':
      return Cloud
    case '04d' || '04n':
      return Cloudy
    case '09d' || '09n':
      return Rain
    case '10d':
      return RainScattered
    case '10n':
      return RainScatteredNight
    case '11d' || '11n':
      return Thunderstorm
    case '13d' || '13n':
      return WinterWarning
    case '50d' || '50n':
      return WindySrong
    default:
      return Sun
  }
}
