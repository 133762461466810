import { useEffect, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

import Header from 'common/components/FullScreen/Header/Header'
import QRContainer from 'common/components/FullScreen/QRContainer/QRContainer'
import CircleDetails from 'common/components/FullScreen/CircleDetails/CircleDetails'
import WeatherInfo from 'common/components/FullScreen/WeatherInfo/WeatherInfo'
import Clock from 'common/components/FullScreen/Clock/Clock'
import NewIndicator from 'common/components/FullScreen/NewIndicators/NewIndicators'
import UpdateTimer from 'common/components/FullScreen/UpdateTimer/UpdateTimer'
import DataTypes from 'constants/DataTypes'
import * as LocalizeStore from 'utils/localizeStore'
import Languages from 'constants/Languages'
import styles from './FullScreenView.module.scss'
import mobileLogo from 'icons/Logotipo.svg'

const FullScreenView = props => {
  const { system, data, onClose, translate, screen, user } = props
  const { weather, micaType } = system
  const { name } = screen
  const handle = useFullScreenHandle()
  const [fullscreen, setFullscreen] = useState(true)

  const langData = LocalizeStore.getLocalizeStoreData()
  const lang = langData ? langData.lang : Languages[0].code // default spanish

  useEffect(() => {
    handle.enter()

    window.history.pushState(null, null, document.URL)
    window.addEventListener('popstate', event => {
      onClose()
    })

    return () => {
      if (handle.active) {
        handle.exit()
      }
      onClose()
    }
  }, [])

  const isIndicator = item => {
    return (
      item.type === DataTypes.IAQ ||
      item.type === DataTypes.COVID_19 ||
      item.type === DataTypes.VENTILATION_INDICATOR ||
      item.type === DataTypes.THERMAL_INDICATOR
    )
  }
  const getIndicatorText = item => {
    if (item.measurements[0].value > 83) {
      return translate('fullScreenPage.Excellent')
    } else if (item.measurements[0].value > 65) {
      return translate('fullScreenPage.Good')
    } else if (item.measurements[0].value > 48) {
      return translate('fullScreenPage.Moderate')
    } else if (item.measurements[0].value > 32) {
      return translate('fullScreenPage.Regular')
    } else if (item.measurements[0].value > 16) {
      return translate('fullScreenPage.Inadequate')
    } else {
      return translate('fullScreenPage.Poor')
    }
  }

  const getIndicatorTitle = type => {
    switch (type) {
      case DataTypes.COVID_19:
        return translate('ViricoIndicator')
      case DataTypes.VENTILATION_INDICATOR:
        return translate('VentilaciónIndicator')
      case DataTypes.THERMAL_INDICATOR:
        return translate('indicatorThermico')
      default:
        return translate('iaqInterior')
    }
  }
  const indicatorValue = data.filter(t => isIndicator(t))[0]
  let indicatorValuess
  if (indicatorValue) {
    indicatorValuess = indicatorValue.measurements[0].value
  }

  const dataConnected = data.filter(
    item => !indicatorValue || (item.type !== indicatorValue.type && item.measurements.length > 0)
  ).length

  const hasOldO3Units = () => {
    return (
      system.model === 'MICA_WELL' || system.model === 'MICA_WELL_GSM' || system.model === 'CARTIF'
    )
  }

  return (
    <FullScreen
      handle={handle}
      onChange={(state, handle) => {
        setFullscreen(false)
        if (fullscreen === state) {
          onClose()
        }
      }}
    >
      <div className={styles.box}>
        <div className={styles.headerRow}>
          <div className={styles.header}>
            <Header
              name={name}
              type={micaType}
              userLogoUrl={screen.showLogo ? user.logo : null}
              translate={translate}
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.paramList}>
            {indicatorValue && (
              <div
                className={` ${
                  dataConnected > 0
                    ? 'col-lg-3 pl-2.2 pr-2.2 full_width'
                    : 'col-lg-12 pl-2.2 pr-2.2 full_width'
                }`}
              >
                <div className={`background_shadow  ${styles.full_width}`}>
                  <h1 className={styles.titleIndic}>{getIndicatorTitle(indicatorValue.type)}</h1>
                  <div className={dataConnected > 0 ? styles.boxIndicators : styles.noParams}>
                    <div className={styles.indicatorDetails}>
                      <NewIndicator numericValue={indicatorValuess} />
                    </div>
                  </div>
                  <p className={styles.indicatorText}>{getIndicatorText(indicatorValue)}</p>
                </div>
              </div>
            )}

            {dataConnected > 0 && (
              <div
                className={
                  indicatorValue
                    ? 'col-lg-9 pl-2.2 pr-2.2 full_width'
                    : 'col-lg-12 pl-2.2 pr-2.2 full_width'
                }
              >
                <div className={`background_shadow  ${styles.full_width}`}>
                  <div className={styles.fullHeight}>
                    <div className={styles.paramtersBox}>
                      {data
                        .filter(
                          item =>
                            !indicatorValue ||
                            (item.type !== indicatorValue.type && item.measurements.length > 0)
                        )
                        .map(item => (
                          <CircleDetails
                            key={item.type}
                            item={item}
                            translate={translate}
                            dataCount={dataConnected}
                            indicatorValue={indicatorValue}
                            user={user}
                            oldO3Units={hasOldO3Units()}
                          />
                        ))}
                    </div>
                  </div>
                  <UpdateTimer
                    timestamp={data[0].measurements[0] ? data[0].measurements[0].date : null}
                    translate={translate}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.foot}>
            <div className='col-lg-2 pl-2.2 pr-2.2'>
              <Clock lang={lang} />
            </div>

            <div className='col-lg-8 pl-2.2 pr-2.2'>
              {weather && (
                <WeatherInfo
                  weather={weather}
                  user={user}
                  translate={translate}
                  system={system}
                />
              )}
            </div>
            <div className='col-lg-2 pl-2.2 pr-2.2'>
              <QRContainer
                screenId={screen.ScreenId}
                translate={translate}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.footResponsive}>
            <div className='col-lg-12 pl-2.2 pr-2.2'>
              {weather && (
                <WeatherInfo
                  user={user}
                  weather={weather}
                  translate={translate}
                  system={system}
                />
              )}
            </div>
          </div>
          <div className={styles.inbiotLink}>
            <div className='col-lg-12 pl-2.2 pr-2.2'>
              <div className={styles.alignLink}>
                <img
                  src={mobileLogo}
                  className={styles.logo}
                  alt='inBiot'
                />
                <a
                  className={styles.linkStyle}
                  href='https://www.inbiot.es'
                  target='_blank'
                  rel='noreferrer'
                >
                  www.inbiot.es
                </a>
              </div>
            </div>
          </div>
          <div className={styles.responsiveFooter}>
            <div className='col-lg-6 pl-2 pr-2'>
              <Clock lang={lang} />
            </div>
            <div className='col-lg-6 pl-2 pr-2'>
              <QRContainer
                screenId={screen.ScreenId}
                translate={translate}
              />
            </div>
          </div>
        </div>
      </div>
    </FullScreen>
  )
}

export default FullScreenView
