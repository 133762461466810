import { createReducer } from 'utils'
import { fromJS } from 'immutable'

import { AUTH_USER_REQUEST, LOGIN_REQUEST, LOGOUT } from 'common/actions/auth'

export const initialState = fromJS({
  user: null,
  isLoggedIn: false,
  isLoaded: false
})

function mergeUser(state, { payload }) {
  return state.set('user', payload.result).set('isLoggedIn', true).set('isLoaded', true)
}

export default createReducer(initialState, {
  [LOGOUT]: state => state.set('isLoaded', true),
  [LOGIN_REQUEST.SUCCESS]: mergeUser,
  [AUTH_USER_REQUEST.SUCCESS]: mergeUser,
  [AUTH_USER_REQUEST.FAILURE]: () => initialState.set('isLoaded', true)
})
