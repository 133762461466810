import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { localizeReducer } from 'react-localize-redux'
import { reducer as formReducer } from 'redux-form'

import entities from './entities'
import user from './user'
import modal from './modal'
import loader from './loader'
import snackbar from './snackbar'

import { LOGOUT } from 'common/actions/auth'

export const makeRootReducer = (asyncReducers = {}) => {
  const appReducer = combineReducers({
    // add app reducers here
    entities,
    user,
    modal,
    loader,
    snackbar,

    // 3th party
    routing: routerReducer,
    form: formReducer,
    localize: localizeReducer,

    ...asyncReducers
  })

  return (state, action) => {
    if (action.type === LOGOUT) {
      const { routing, localize } = state

      state = { routing, localize }
    }

    return appReducer(state, action)
  }
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
    return
  }

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
