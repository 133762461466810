import MainLogo from 'icons/main_logo.svg'
import mobileLogo from 'icons/Logotipo.svg'
import styles from './Header.module.scss'

const Header = ({ userLogoUrl, translate, name }) => {
  return (
    <header>
      {userLogoUrl && (
        <div className={styles.responsiveUserLogo}>
          <img
            src={userLogoUrl}
            className={styles.headerLogo}
            alt='userLogo'
          />
        </div>
      )}
      {!userLogoUrl && (
        <div className={styles.responsiveLogo}>
          <img
            src={mobileLogo}
            className={styles.headerLogo}
            alt='inBiot'
          />
        </div>
      )}
      <img
        src={MainLogo}
        className={styles.main_logo}
        alt='inBiot'
      />
      <div className={styles.verticallyAligned}>
        <h1 className={styles.fullScreenHeader}>{translate('fullScreenPage.micaHeading')}</h1>
        <h5>{name}</h5>
      </div>
      {userLogoUrl ? (
        <img
          className={styles.logo}
          src={userLogoUrl}
          alt='Logo'
        />
      ) : (
        <div className={styles.empty}>{}</div>
      )}
    </header>
  )
}

export default Header
