import { schema } from 'normalizr'

export const data = new schema.Entity(
  'data',
  {},
  {
    idAttribute: '_id'
  }
)

export const weather = new schema.Entity(
  'weather',
  {},
  {
    idAttribute: 'baseStationLocation'
  }
)

export const system = new schema.Entity(
  'systems',
  {
    data: [data],
    weather
  },
  {
    idAttribute: '_id'
  }
)

export const screen = new schema.Entity(
  'screens',
  {},
  {
    idAttribute: 'ScreenId'
  }
)

export const alarm = new schema.Entity(
  'alarms',
  {},
  {
    idAttribute: 'AlarmId'
  }
)

export const inactivityAlarm = new schema.Entity(
  'inactivityAlarms',
  {},
  {
    idAttribute: 'InactivityAlarmId'
  }
)

export const user = new schema.Entity(
  'users',
  {
    systems: [system]
  },
  {
    idAttribute: '_id'
  }
)
