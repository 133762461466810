import { createReducer } from 'utils'
import { fromJS } from 'immutable'
import { TOGGLE_LOADER } from '../actions/loader'

export const initialState = fromJS({
  isVisible: false
})

export default createReducer(initialState, {
  [TOGGLE_LOADER]: (state, { payload: isVisible = true }) => state.set('isVisible', isVisible)
})
