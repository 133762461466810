import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { getUserId } from '../selectors/user'
import * as AuxStore from '../../utils/auxStore'
import actions, { constants } from 'common/actions/measurementSystem'
import * as api from 'api/user'
import snackbarActions from 'common/actions/snackbar'

export function* onSetActiveMeasurementSystem({ payload: measurementSystem }) {
  const userId = yield select(getUserId)
  // If no user id means no user is logged in
  // In that case, just set the language in the local storage
  // And reload the window
  if (!userId) {
    yield call(AuxStore.setUserMeasurementSystem, measurementSystem)
    window.location.reload()
  }

  // Otherwise
  // Request set language, on success:
  // Set language in local storage and reload the window
  yield put(actions.setActiveMeasurementSystemRequest.start())

  try {
    yield call(api.setUserMeasurementSystem, userId, {
      measurementSystem
    })
    yield put(actions.setActiveMeasurementSystemRequest.success())

    yield call(AuxStore.setUserMeasurementSystem, measurementSystem)
    window.location.reload()
  } catch (err) {
    yield put(actions.setActiveMeasurementSystemRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export default function* watchMeasurementSystem() {
  yield all([takeLatest(constants.SET_ACTIVE_MEASUREMENT_SYSTEM, onSetActiveMeasurementSystem)])
}
