import request from 'utils/request'
import { Auth } from 'aws-amplify'

export function forgottenPassword(data) {
  return request('password/forgotten', {
    method: 'POST',
    data
  })
}

export async function forgottenPasswordCognito(data) {
  try {
    return await Auth.forgotPassword(data.email)
  } catch (error) {
    return error
  }
}

export function recoverPassword(data) {
  return request('password/recover', {
    method: 'POST',
    data
  })
}

export async function recoverPasswordCognito(data) {
  try {
    return await Auth.forgotPasswordSubmit(data.email, data.verificationCode, data.password)
  } catch (error) {
    return error
  }
}
