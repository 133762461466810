import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push as pushHistory } from 'react-router-redux'

import * as Routes from 'constants/Routes'

import { getIsLoggedIn, getIsLoaded as getIsUserLoaded } from 'common/selectors/user'

export default function requireAuth(Component) {
  class Authenticated extends React.Component {
    static propTypes = {
      location: PropTypes.object,
      isLoggedIn: PropTypes.bool.isRequired,
      isUserLoaded: PropTypes.bool.isRequired,
      pushHistory: PropTypes.func.isRequired
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      this.checkAuth(nextProps.isLoggedIn)
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
      this.checkAuth(this.props.isLoggedIn)
    }

    checkAuth(isLoggedIn) {
      if (!isLoggedIn) {
        this.props.pushHistory(Routes.LOGIN)
      }
    }

    render() {
      return this.props.isUserLoaded && this.props.isLoggedIn && <Component {...this.props} />
    }
  }

  const mapStateToProps = state => ({
    isLoggedIn: getIsLoggedIn(state),
    isUserLoaded: getIsUserLoaded(state)
  })

  const mapActionsToProps = {
    pushHistory
  }

  return connect(mapStateToProps, mapActionsToProps)(Authenticated)
}
