import { LOCALIZE_STORE_NAMESPACE } from '../config'
import * as storage from './storage'

export const setAuthStoreData = lang => {
  storage.setItem(LOCALIZE_STORE_NAMESPACE, { lang })
}

export const getLocalizeStoreData = () => {
  return storage.getItem(LOCALIZE_STORE_NAMESPACE)
}

export const clearLocalizeStoreData = () => {
  storage.removeItem(LOCALIZE_STORE_NAMESPACE)
}
