import { fromJS } from 'immutable'
import { createReducer } from 'utils'

import { AUTH_USER_REQUEST, LOGIN_REQUEST, LOGOUT } from '../actions/auth'

import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  ADD_SYSTEM_TO_USER_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,
  UPLOAD_USER_LOGO_REQUEST,
  DELETE_USER_LOGO_REQUEST,
  CONFIRM_USER_NEWS_REQUEST,
  DELETE_ALERT_RECIPIENT_REQUEST,
  ADD_ALERT_RECIPIENT_REQUEST,
  DELETE_USER_DEVICES_REQUEST,
  ADD_COLLABORATIVE_ACCOUNT_REQUEST,
  EDIT_COLLABORATIVE_ACCOUNT_REQUEST,
  DELETE_COLLABORATIVE_ACCOUNT_REQUEST,
  DELETE_COLLABORATIVE_ACCESS_REQUEST
} from '../actions/user'

import {
  GET_SYSTEM_BY_ID_REQUEST,
  GET_SYSTEMS_HOME_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  UPDATE_SYSTEM_DATA_REQUEST,
  POLL_SYSTEM_REQUEST,
  POLL_SYSTEMS_REQUEST,
  DELETE_DATA_FROM_USER_SYSTEM_REQUEST,
  CREATE_GROUP_REQUEST,
  UPDATE_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SYSTEM_REQUEST,
  GET_SCREENS_REQUEST,
  CREATE_SCREEN_REQUEST,
  UPDATE_SCREEN_REQUEST,
  DELETE_SCREEN_REQUEST,
  GET_ALARMS_REQUEST,
  CREATE_ALARM_REQUEST,
  CREATE_REPORT_REQUEST,
  DELETE_ALARM_REQUEST,
  UPDATE_ALARM_REQUEST,
  CHANGE_ALARM_STATUS_REQUEST,
  CREATE_INACTIVITY_ALARM_REQUEST,
  UPDATE_INACTIVITY_ALARM_REQUEST,
  DELETE_INACTIVITY_ALARM_REQUEST,
  GET_INACTIVITY_ALARMS_REQUEST,
  CHANGE_INACTIVITY_ALARM_STATUS_REQUEST,
  UPDATE_SYSTEM_LIGHTS_REQUEST,
  UPDATE_SYSTEM_CALIBRATION_REQUEST,
  UPDATE_SYSTEM_PERIODICITY_REQUEST,
  UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST,
  UPDATE_SYSTEM_GLOBAL_CALIBRATION_REQUEST,
  UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST
} from 'common/actions/systems'

import {
  GET_FULLSCREEN_BY_ID_REQUEST,
  GET_FULLSCREEN_USER_REQUEST,
  POLL_FULLSCREEN_REQUEST,
  POLL_CHANNEL_REQUEST
} from '../actions/fullscreen'

export const initialState = fromJS({
  users: {},
  systems: {},
  data: {},
  weather: {},
  screens: {},
  alarms: {},
  inactivityAlarms: {}
})

const mergeEntities = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}

const updateEntities = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.setIn([entity], fromJS(payload.entities[entity])),
      state
    )
  )
}

const setUser = (state, { payload }) => {
  return state.setIn(
    ['users', String(payload.result)],
    fromJS(payload.entities.users[payload.result])
  )
}
const setUpdateAlarms = (state, { payload }) => {
  return state.withMutations(state => {
    state.setIn(['alarms', String(payload.result)], fromJS(payload.entities.alarms[payload.result]))
  })
}

const setUpdateInactivityAlarms = (state, { payload }) => {
  return state.withMutations(state => {
    state.setIn(
      ['inactivityAlarms', String(payload.result)],
      fromJS(payload.entities.inactivityAlarms[payload.result])
    )
  })
}

const setScreens = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}

const setAlarms = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}
const setReports = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}

export default createReducer(initialState, {
  // User actions
  [LOGOUT]: () => initialState,
  [AUTH_USER_REQUEST.SUCCESS]: mergeEntities,
  [LOGIN_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_USER_REQUEST.SUCCESS]: setUser,
  [UPLOAD_USER_LOGO_REQUEST.SUCCESS]: setUser,
  [CONFIRM_USER_NEWS_REQUEST.SUCCESS]: setUser,
  [DELETE_USER_LOGO_REQUEST.SUCCESS]: (state, { payload: { userId } }) => {
    return state.updateIn(['users'], users =>
      users.map(u => {
        if (u.get('_id') !== userId) return u
        return u.set('logo', undefined)
      })
    )
  },
  [UPDATE_USER_PASSWORD_REQUEST.SUCCESS]: setUser,
  [DELETE_ALERT_RECIPIENT_REQUEST.SUCCESS]: setUser,
  [ADD_ALERT_RECIPIENT_REQUEST.SUCCESS]: setUser,
  [ADD_SYSTEM_TO_USER_REQUEST.SUCCESS]: mergeEntities,
  [ADD_COLLABORATIVE_ACCOUNT_REQUEST.SUCCESS]: setUser,
  [EDIT_COLLABORATIVE_ACCOUNT_REQUEST.SUCCESS]: setUser,
  [DELETE_COLLABORATIVE_ACCOUNT_REQUEST.SUCCESS]: setUser,
  [DELETE_COLLABORATIVE_ACCESS_REQUEST.SUCCESS]: setUser,
  [DELETE_USER_DEVICES_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceIds = data.deviceIds
    // Manually remove:
    // - system from systems

    return state.updateIn(['systems'], systems =>
      systems.filter(s => !deviceIds.includes(s.get('_id')))
    )
  },

  [DELETE_SYSTEM_FROM_USER_REQUEST.SUCCESS]: (state, { payload: { userId, systemId } }) => {
    const system = state.getIn(['systems', String(systemId)])
    const dataIds = system.get('data')
    // Manually remove:
    // - system from systems
    // - all data from system
    return state
      .updateIn(['systems'], systems => systems.filter(s => s.get('_id') !== systemId))
      .updateIn(['data'], data => data.filter(d => !dataIds.includes(d.get('_id'))))
  },

  // System actions
  [GET_SYSTEM_BY_ID_REQUEST.SUCCESS]: mergeEntities,
  [GET_SYSTEMS_HOME_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SYSTEM_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SYSTEM_DATA_REQUEST.SUCCESS]: (state, { payload: systemId }) => {
    const system = state.getIn(['systems', String(systemId)])
    const dataIds = system.get('data')

    // (Re)set: data measurements so that can get filled again on POLL_SYSTEM_REQUEST.SUCCESS
    return state.updateIn(['data'], data =>
      data.map(d => {
        if (!dataIds.includes(d.get('_id'))) return d

        return d.set('measurements', [])
      })
    )
  },
  [UPDATE_SYSTEM_LIGHTS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const ledOn = data.ledOn
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('ledOn', ledOn)
      })
    )
  },
  [UPDATE_SYSTEM_CALIBRATION_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const ventilationType = data.ventilationType
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('ventilationType', ventilationType)
      })
    )
  },
  [UPDATE_SYSTEM_PERIODICITY_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const timeInterval = data.timeInterval
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('timeInterval', timeInterval)
      })
    )
  },
  [UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const ledOn = data.ledOn
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        return s.set('ledOn', ledOn)
      })
    )
  },
  [UPDATE_SYSTEM_GLOBAL_CALIBRATION_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const ventilationType = data.ventilationType
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        return s.set('ventilationType', ventilationType)
      })
    )
  },
  [UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const timeInterval = data.timeInterval
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        return s.set('timeInterval', timeInterval)
      })
    )
  },

  [DELETE_DATA_FROM_USER_SYSTEM_REQUEST.SUCCESS]: (state, { payload: { systemId } }) => {
    const system = state.getIn(['systems', String(systemId)])
    const dataIds = system.get('data')

    return state.updateIn(['data'], data =>
      data.map(d => {
        if (!dataIds.includes(d.get('_id'))) return d

        return d.set('measurements', [])
      })
    )
  },
  [CREATE_GROUP_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_GROUP_REQUEST.SUCCESS]: mergeEntities,
  [DELETE_GROUP_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceIds = data.deviceIds
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (!deviceIds.includes(s.get('_id'))) return s
        return s.set('group', undefined)
      })
    )
  },
  [DELETE_GROUP_SYSTEM_REQUEST.SUCCESS]: (state, { payload: { systemId } }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== systemId) return s
        return s.set('group', undefined)
      })
    )
  },

  [POLL_SYSTEMS_REQUEST.SUCCESS]: mergeEntities,
  [POLL_SYSTEM_REQUEST.SUCCESS]: mergeEntities,

  // FullScreenLink actions
  [GET_FULLSCREEN_BY_ID_REQUEST.SUCCESS]: mergeEntities,
  [GET_FULLSCREEN_USER_REQUEST.SUCCESS]: setUser,
  [POLL_FULLSCREEN_REQUEST.SUCCESS]: updateEntities,
  [POLL_CHANNEL_REQUEST.SUCCESS]: mergeEntities,

  [GET_SCREENS_REQUEST.SUCCESS]: setScreens,
  [CREATE_SCREEN_REQUEST.SUCCESS]: setScreens,
  [UPDATE_SCREEN_REQUEST.SUCCESS]: setScreens,
  [DELETE_SCREEN_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['screens'], screens => screens.filter(s => s.get('ScreenId') !== data))
  },

  [CREATE_REPORT_REQUEST.SUCCESS]: setReports,

  [GET_ALARMS_REQUEST.SUCCESS]: setAlarms,
  [CREATE_ALARM_REQUEST.SUCCESS]: setAlarms,
  [UPDATE_ALARM_REQUEST.SUCCESS]: setUpdateAlarms,
  [DELETE_ALARM_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['alarms'], alarms => alarms.filter(a => a.get('AlarmId') !== data))
  },
  [CHANGE_ALARM_STATUS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['alarms'], alarms =>
      alarms.map(a => {
        if (a.get('AlarmId') !== data.AlarmId) return a
        return a.set('active', data.jsonData.active)
      })
    )
  },

  [GET_INACTIVITY_ALARMS_REQUEST.SUCCESS]: setAlarms,
  [CREATE_INACTIVITY_ALARM_REQUEST.SUCCESS]: setAlarms,
  [UPDATE_INACTIVITY_ALARM_REQUEST.SUCCESS]: setUpdateInactivityAlarms,
  [DELETE_INACTIVITY_ALARM_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['inactivityAlarms'], inactivityAlarms =>
      inactivityAlarms.filter(a => a.get('InactivityAlarmId') !== data)
    )
  },
  [CHANGE_INACTIVITY_ALARM_STATUS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['inactivityAlarms'], inactivityAlarms =>
      inactivityAlarms.map(a => {
        if (a.get('InactivityAlarmId') !== data.InactivityAlarmId) return a
        return a.set('active', data.jsonData.active)
      })
    )
  }
})
