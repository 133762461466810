import { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

// Material-UI imports
import { Modal } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

// Imports from own project
import styles from './TypeInfoModalContainer.module.scss'
import MicaTypes from 'constants/MicaTypes'
import { CloseIcon, Ranges, EngRanges } from 'icons'

import NewIndicator from '../../../../common/components/Indicators/NewIndicators/NewIndicators'
const ConfirmationModal = props => {
  const { hideModal, translate, type, activeLanguage, numericValue, vocsType } = props

  const [open] = useState(true)

  const getTitle = type => {
    switch (type) {
      case 'ventilation':
        return translate('ventilationInfoTextTitle')
      case MicaTypes.MICA_DESK:
        return translate('micaDeskInfoTextTitle')
      case MicaTypes.MICA_LITE:
        return translate('micaLiteInfoTextTitle')
      case MicaTypes.MICA:
        return translate('micaDeskInfoTextTitle')
      case MicaTypes.MICA_PLUS:
        return translate('micaDeskInfoTextTitle')
      case MicaTypes.MICA_MINI:
        return translate('micaMiniInfoTextTitle')
      case MicaTypes.MICA_WELL:
        return translate('micaDeskInfoTextTitle')
      default:
        break
    }
  }

  const getContent = type => {
    switch (type) {
      case 'ventilation':
        if (vocsType.length > 0) {
          return (
            <Translate
              id='ventilationInfo'
              options={{ renderInnerHtml: true }}
            />
          )
        } else {
          return (
            <Translate
              id='ventilationInfoVocs'
              options={{ renderInnerHtml: true }}
            />
          )
        }

      case MicaTypes.MICA_DESK:
        return (
          <Translate
            id='micaDeskInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      case MicaTypes.MICA_LITE:
        return (
          <Translate
            id='micaLiteInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      case MicaTypes.MICA:
        return (
          <Translate
            id='micaInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      case MicaTypes.MICA_PLUS:
        return (
          <Translate
            id='micaInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      case MicaTypes.MICA_MINI:
        return (
          <Translate
            id='micaMiniInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      case MicaTypes.MICA_WELL:
        return (
          <Translate
            id='micaWellInfo'
            options={{ renderInnerHtml: true }}
          />
        )
      default:
        break
    }
  }

  const handleMoreInformationClick = type => {
    switch (type) {
      case 'ventilation':
        return 'https://www.inbiot.es/wikinbiot/indicador-eficacia-ventilacion'
      case MicaTypes.MICA_DESK:
        return 'https://inbiot.es/wikinbiot/indicador-calidad-de-aire-interior'
      case MicaTypes.MICA_LITE:
        return 'https://inbiot.es/wikinbiot/indicador-resistencia-virus'
      case MicaTypes.MICA:
        return 'https://inbiot.es/wikinbiot/indicador-calidad-de-aire-interior'
      case MicaTypes.MICA_PLUS:
        return 'https://inbiot.es/wikinbiot/indicador-calidad-de-aire-interior'
      case MicaTypes.MICA_MINI:
        return 'https://inbiot.es/wikinbiot/indicadore-confort-termohigrometrico'
      case MicaTypes.MICA_WELL:
        return 'https://inbiot.es/wikinbiot/indicador-calidad-de-aire-interior'
    }
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <div className={styles.modalContainer}>
          <div className={styles.closeButtonPosition}>
            <button
              className={styles.closeModalArrow}
              onClick={hideModal}
            >
              <img
                src={CloseIcon}
                alt='close'
                className={styles.iconButton}
              />
            </button>
          </div>

          <Typography className={styles.header}>{getTitle(type)}</Typography>
          {numericValue !== undefined && (
            <div className={styles.indicatorWrapper}>
              <NewIndicator numericValue={numericValue} />
            </div>
          )}
          <div className={styles.modal}>
            {getContent(type)}
            <div className={styles.contentImage}>
              <img
                src={activeLanguage.code === 'es' ? Ranges : EngRanges}
                alt='Images'
                className={styles.images}
              />
            </div>
          </div>
          <div className={styles.infoButton}>
            <a
              href={handleMoreInformationClick(type)}
              target='_blank'
              className={styles.informationButton}
              rel='noreferrer'
            >
              {translate('learnMore')}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default ConfirmationModal
