/* eslint-disable no-useless-escape */
// ---------------------- External imports ------------------------ //
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

// ---------------------- Material-UI imports ------------------------ //
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  IconButton,
  TextField,
  Typography,
  Input,
  Button,
  InputAdornment,
  FormControlLabel,
  FormControl,
  Select,
  ListItem,
  ListItemText,
  Collapse,
  MenuItem
} from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import { Alert } from '@material-ui/lab'
import { LoadingButton } from '@mui/lab'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
// ---------------------- Internal imports ------------------------ //
import { getUser } from 'common/selectors/user'
import styles from './EditInactivityAlertModalForm.module.scss'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const EditInactivityAlertModalForm = props => {
  const {
    onSubmit,
    user,
    translate,
    isSubmitting,
    systemsByGroup,
    submitError,
    alarm,
    systems,
    addAlertRecipient,
    onDeleteAlertRecipient
  } = props

  const classes = useStyles()

  const checkedEmails = {}

  useEffect(() => {
    const emailsConfAux = []
    const emailsUnconfAux = []
    for (let i = 0; i < user.toJS().confirmedRecipients?.length; i++) {
      emailsConfAux[i] = {
        email: user.toJS().confirmedRecipients[i],
        confirmed: true
      }
    }
    for (let i = 0; i < user.toJS().unconfirmedRecipients?.length; i++) {
      emailsUnconfAux[i] = {
        email: user.toJS().unconfirmedRecipients[i],
        confirmed: false
      }
    }
    setEmailsConf(emailsConfAux)
    setEmailsUnconf(emailsUnconfAux)
    setSearched([...emailsConfAux, ...emailsUnconfAux])
  }, [user])

  const [timeRangeValue, setTimeRangeValue] = useState(alarm.userPeriod.rangeValue)
  const [unitValue, setUnitValue] = useState(alarm.userPeriod.rangeUnit)
  const [searched, setSearched] = useState([])
  const [selectedDevices, setSelectedDevices] = useState(Object.keys(alarm.Devices))
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)

  const [openInfo, setOpenInfo] = useState()
  const [openInfoE, setOpenInfoE] = useState({})
  const [emailsConf, setEmailsConf] = useState([])
  const [emailsUnconf, setEmailsUnconf] = useState([])
  const [emails, setEmails] = useState(checkedEmails)
  const [activeTab, setActiveTab] = useState(0)
  const [dataState, setDataState] = useState({})
  const [emailForAdd, setEmailForAdd] = useState('')
  const [systemsGroupState, setSystemsGroupState] = useState(systemsByGroup)
  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  const [open, setOpen] = useState({})
  const [alarmName, setAlarmName] = useState(alarm.name)

  const { handleSubmit, register, errors, getValues, setError, clearErrors } = useForm()

  const handleInfoE = () => {
    setOpenInfoE(!openInfoE)
  }

  const handleInfo = () => {
    setOpenInfo(!openInfo)
  }

  const handleChangeSelectedEmails = event => {
    setEmails({
      ...emails,
      [event.target.value]: event.target.checked
    })
  }

  const handleTimeInputChange = event => {
    setTimeRangeValue(event.target.value)
    if (event.target.value !== '') {
      clearErrors('rangeUnit')
    }
    if (Number(event.target.value) > 0) {
      clearErrors('rangeValue')
    }
  }

  const handleChangeInactivityUnit = event => {
    setUnitValue(event.target.value)
    if (event.target.value !== '') {
      clearErrors('rangeUnit')
    }
  }

  const onClickNext = () => {
    const values = getValues()
    if (activeTab === 0) {
      if (!Array.isArray(values.selectedDevices)) values.selectedDevices = [values.selectedDevices]
      if (values && values.selectedDevices !== undefined && values.selectedDevices.length > 0) {
        clearErrors('selectedDevices')
        setActiveTab(1)
      } else {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }
    } else {
      if (activeTab === 1) {
        if (values.rangeUnit === '' || values.rangeValue === '') {
          setError('rangeUnit', { shouldFocus: 'rangeUnit' })
        } else {
          if (Number(values.rangeValue) === 0) {
            setError('rangeValue', { shouldFocus: 'rangeValue' })
          } else {
            setActiveTab(2)
          }
        }
      }
    }
  }
  const onClickBack = () => {
    if (activeTab === 2) {
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }

  const checkInputs = () => {
    const data = {}
    const rangeUnit = unitValue
    clearErrors('emailForAdd')
    const values = getValues()
    if (values.emailRecipients.length === 0) {
      setError('emailRecipients', { shouldFocus: 'emailRecipients' })
    } else {
      clearErrors('emailRecipients')
    }

    if (alarmName === '') {
      setError('alarmName', { shouldFocus: 'alarmName' })
    } else {
      clearErrors('alarmName')
    }
    if (Object.keys(errors).length === 0) {
      data.devices = Array.isArray(values.selectedDevices)
        ? values.selectedDevices
        : [values.selectedDevices]
      data.alarmName = alarmName
      data.rangeValue = values.rangeValue
      data.rangeUnit = rangeUnit
      data.emailRecipients = values.emailRecipients
      setDataState(data)
      onSubmit(data)
    }
  }

  const onClickCreate = () => {
    checkInputs()
  }

  const onClickAdd = () => {
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (getValues('emailForAdd').match(emailFormat)) {
      addAlertRecipient({
        email: getValues('emailForAdd'),
        userId: user.toJS()._id
      })
      setSearched([...searched, { email: getValues('emailForAdd'), confirmed: false }])
    } else {
      setError('emailForAdd', { shouldFocus: 'emailForAdd' })
    }
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const handleChangeAddEmail = event => {
    setEmailForAdd(event.target.value)
    if (event.target.value !== '') {
      clearErrors('emailForAdd')
    }
  }

  const error = () => {
    if (!submitError) return null
    let message = translate('submitErrors.unknown')
    if (submitError.res && submitError.res.status === 404) {
      message = translate('submitErrors.systemNotFound')
    } else if (submitError.res && submitError.res.status === 409) {
      message = translate('submitErrors.systemAlreadyInUse')
    }

    return (
      <Alert
        severity='error'
        className={styles.error}
      >
        {message}
      </Alert>
    )
  }

  const filterEmailsChange = event => {
    let searchedUnconfEmails = []
    searchedUnconfEmails = emailsUnconf.filter(e => {
      return e.email.indexOf(event.target.value) !== -1
    })
    let searchedConfEmails = []
    searchedConfEmails = emailsConf.filter(e => {
      return e.email.indexOf(event.target.value) !== -1
    })
    setSearched([...searchedConfEmails, ...searchedUnconfEmails])
  }

  useEffect(() => {
    const emails = {}
    for (let i = 0; i < alarm.emailRecipients?.length; i++) {
      emails[alarm.emailRecipients[i]] = true
    }
    if (!alarm.emailRecipients) emails[user.toJS().email] = true
    setEmails(emails)
  }, [alarm.emailRecipients])
  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }
  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }
  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setSelectedDevices([])
    } else {
      const systemIds = []
      systems.map(s => {
        !getSystemsWithInactivityAlarmId(systems).includes(s._id) && systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }
  const handleSelectAllGroupDevices = (event, systems) => {
    if (event.target.checked) {
      const aux = []
      for (let i = 0; i < systems.length; i++) {
        if (
          !selectedDevices.includes(systems[i]._id) &&
          !getSystemsWithInactivityAlarmId(systems).includes(systems[i]._id)
        )
          aux.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        if (!getSystemsWithInactivityAlarmId(systems).includes(systems[i]._id))
          systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
    }
  }
  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
      clearErrors('selectedDevices')
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }
  const handleChangeAlarmName = event => {
    setAlarmName(event.target.value)
    if (event.target.value !== '') {
      clearErrors('alarmName')
    }
  }

  const getSystemsWithInactivityAlarmId = systems => {
    return systems
      .filter(system => system.inactivityAlarmId)
      .map(system => !Object.keys(alarm.Devices).includes(system._id) && system._id)
      .filter(Boolean)
  }
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('alertsPage.editAlert')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('addInactivityAlertModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('addInactivityAlertModal.navValues')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('addInactivityAlertModal.navEmails')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={filterExpressionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('searchDevice')}
                  className={cx(styles.input, classes.input)}
                />
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAllDevices'
                      id='selectAllDevices'
                      indeterminate={isIndeterminateDevices()}
                      onChange={handleSelectAllDevices}
                      checked={isCheckAllDevices}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <Paper
                  className={styles.devicesListPaper}
                  classes={{ root: classes.paper }}
                >
                  <List
                    disableTouchRipple
                    className={styles.devicesList}
                  >
                    {_.map(systemsGroupState, function (systems, group) {
                      const systemsType = _.groupBy(systems, 'micaType')
                      return (
                        <div className={styles.noGroupWrapper}>
                          {group !== 'undefined' && (
                            <div>
                              <ListItem
                                button
                                disableTouchRipple
                                className={styles.groupListItem}
                              >
                                {!open[group] ? (
                                  <ExpandLess
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                ) : (
                                  <ExpandMore
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                )}
                                <Checkbox
                                  className={cx(styles.checkbox)}
                                  classes={{ checked: styles.checkedBox }}
                                  name={group}
                                  onChange={e => handleSelectAllGroupDevices(e, systems)}
                                  id={group}
                                />
                                <ListItemText
                                  primary={group}
                                  className={styles.listItemText}
                                />
                              </ListItem>
                            </div>
                          )}
                          {group === 'undefined' && (
                            <div>
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={type}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={system._id}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                  disabled={
                                                    !Object.keys(alarm.Devices).includes(
                                                      system._id
                                                    ) && system.inactivityAlarmId
                                                  }
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </div>
                          )}

                          {group !== 'undefined' && (
                            <Collapse
                              in={!open[group]}
                              timeout='auto'
                              unmountOnExit
                              classes={{
                                wrapper: classes.wrapper
                              }}
                            >
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={translate(type)}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={'system' + index}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                  disabled={
                                                    !Object.keys(alarm.Devices).includes(
                                                      system._id
                                                    ) && system.inactivityAlarmId
                                                  }
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </Collapse>
                          )}
                        </div>
                      )
                    })}
                  </List>
                </Paper>
                <div className={styles.devicesInfoContainer}>
                  <div className={styles.devicesInfoIcon}>
                    <InfoOutlinedIcon />
                  </div>
                  {translate('onlyOneDeviceInactivity')}
                </div>
                <div className={styles.wrapperError}>
                  {errors.selectedDevices && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyDeviceRequired')}</small>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderParams}>
                    {translate('addInactivityAlertModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('addInactivityAlertModal.navValues')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('addInactivityAlertModal.navEmails')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectValues')}
                  </Typography>
                </div>
                <div className={styles.wrapperInfoE}>
                  <div className={styles.dataInput}>
                    {translate('inactivity')}
                    <div className={styles.wrapperInfoButton}>
                      <IconButton
                        className={styles.infoButton}
                        variant='outlined'
                        onClick={() => handleInfo()}
                        onBlur={() => handleInfo()}
                        tabIndex={0} // Permite que el botón reciba foco
                        value={' '}
                        name='infoParam'
                        disableRipple
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div
                    style={{
                      display: openInfo ? 'block' : 'none'
                    }}
                    className={styles.caja}
                  >
                    {
                      <Translate
                        id={'inactivityInfoN'}
                        options={{ renderInnerHtml: true }}
                      />
                    }
                  </div>
                  <div className={styles.wrapperDataInfo}>
                    <div className={styles.inputField}>
                      <Input
                        classes={{
                          checked: styles.checkedBox,
                          input: styles.inputUnit
                        }}
                        onChange={event => handleTimeInputChange(event)}
                        required
                        position='end'
                        type='number'
                        name={'rangeValue'}
                        value={timeRangeValue}
                        defaultValue={timeRangeValue}
                        inputRef={register()}
                        disableRipple
                      />
                    </div>
                    <div className={styles.unitRangeWrapper}>
                      <FormControl>
                        <Select
                          id='rangeUnit'
                          name={'rangeUnit'}
                          value={unitValue}
                          onChange={handleChangeInactivityUnit}
                          variant='standard'
                          disableUnderline
                          className={styles.select}
                          IconComponent={KeyboardArrowDownIcon}
                          classes={{
                            icon: styles.selectIcon,
                            select: styles.selectFocus
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <MenuItem
                            value='h'
                            classes={{
                              selected: styles.menuItemSelected
                            }}
                          >
                            {translate('graphRangeUnits.hours')}
                          </MenuItem>
                          <MenuItem
                            value='d'
                            classes={{
                              selected: styles.menuItemSelected
                            }}
                          >
                            {translate('graphRangeUnits.days')}
                          </MenuItem>
                          <MenuItem
                            value='s'
                            classes={{
                              selected: styles.menuItemSelected
                            }}
                          >
                            {translate('graphRangeUnits.weeks')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                {errors.rangeUnit && (
                  <div className={styles.error}>
                    <small>{translate('validation.rangeValuesRequired')}</small>
                  </div>
                )}
                {errors.rangeValue && (
                  <div className={styles.error}>
                    <small>{translate('validation.rangeValuesInvalid')}</small>
                  </div>
                )}
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderParams}>
                    {translate('addInactivityAlertModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('addInactivityAlertModal.navValues')}
                  </Typography>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('addInactivityAlertModal.navEmails')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='alarmsName'
                  onChange={handleChangeAlarmName}
                  label={translate('alertsPage.alarmsName')}
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  inputRef={register()}
                  name='alarmsName'
                  autoComplete='alarmsName'
                  value={alarmName}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.alarmName
                  })}
                />
                {errors.alarmNameInvalid && (
                  <div className={styles.error}>
                    <small>{translate('validation.required')}</small>
                  </div>
                )}
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='emailForAdd'
                  label={translate('alertsPage.addEmail')}
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  inputRef={register()}
                  name='emailForAdd'
                  value={emailForAdd}
                  onChange={handleChangeAddEmail}
                  autoComplete='emailForAdd'
                  helperText={`${emailForAdd.length}/128`}
                  inputProps={{ maxLength: 128 }}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.emailForAdd
                  })}
                />
                <div className={styles.wrapperError}>
                  {errors.emailForAdd && (
                    <div className={styles.error}>
                      <small>{translate('validation.inValidEmail')}</small>
                    </div>
                  )}
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonAdd}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickAdd}
                  >
                    {translate('add')}
                  </Button>
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={filterEmailsChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('alertsPage.searchEmail')}
                  className={cx(styles.input, classes.input)}
                />
                <div className={styles.wrapperInfo}>
                  <div className={styles.dataInput}>
                    {translate('selectEmails')}
                    <div className={styles.wrapperInfoButton}>
                      <IconButton
                        className={styles.infoButton}
                        variant='outlined'
                        onClick={() => handleInfoE()}
                        onBlur={() => handleInfoE()}
                        tabIndex={1} // Permite que el botón reciba foco
                        value={' '}
                        name='infoParam'
                        disableRipple
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className={styles.wrapperDataInfo}>
                    <div
                      style={{
                        display: openInfoE ? 'none' : 'block'
                      }}
                      className={styles.cajaEmail}
                    >
                      {
                        <Translate
                          id={'emailInfoN'}
                          options={{ renderInnerHtml: true }}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.checkboxEmailWrapper}>
                  <div className={styles.checkboxFormDiv}>
                    <FormControlLabel
                      className={(styles.checkboxForm, styles.confirmed)}
                      control={
                        <Checkbox
                          className={cx(styles.checkbox)}
                          id={user.toJS().email}
                          onChange={handleChangeSelectedEmails}
                          classes={{ checked: styles.checkedBox }}
                          required
                          checked={emails[user.toJS().email] === true}
                          name='emailRecipients'
                          value={user.toJS().email}
                          inputRef={register()}
                          disableRipple
                        />
                      }
                      label={user.toJS().email + ' ' + translate('thisUser')}
                    />
                  </div>
                  {searched.map((email, i) => {
                    return (
                      <div
                        key={i + 'email'}
                        className={styles.checkboxFormDiv}
                      >
                        <FormControlLabel
                          className={
                            (styles.checkboxForm,
                            `${
                              email.confirmed ? `${styles.confirmed}` : `${styles.notConfirmed}`
                            } `)
                          }
                          control={
                            <Checkbox
                              className={cx(styles.checkbox)}
                              id={email}
                              onChange={handleChangeSelectedEmails}
                              classes={{ checked: styles.checkedBox }}
                              required
                              checked={emails[email.email] === true}
                              name='emailRecipients'
                              value={email.email}
                              inputRef={register()}
                              disableRipple
                            />
                          }
                          label={email.email}
                        />
                        <IconButton
                          onClick={() => onDeleteAlertRecipient(email.email)}
                          className={styles.deleteEmailRecipientIcon}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </div>
                    )
                  })}
                </div>
                {errors.emailRecipients && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyEmailRequired')}</small>
                  </div>
                )}
                <div className={styles.errorWrapper}>{error()}</div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    fullWidth
                    variant='contained'
                    className={styles.buttonAdd}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    loadingPosition='end'
                    onClick={onClickCreate}
                  >
                    {translate('saveChanges')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

EditInactivityAlertModalForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.any,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state)
})

export default withLocalize(connect(mapStateToProps)(EditInactivityAlertModalForm))
