import { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'

import { Modal, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './EditFullScreenViewContainer.module.scss'
import { getUser } from 'common/selectors/user'
import { showSnackbar } from 'common/actions/snackbar'
import { updateScreen } from 'common/actions/systems'
import EditFullScreenViewForm from '../components/EditFullScreenViewForm/EditFullScreenViewForm'

const EditFullScreenViewContainer = props => {
  const [open] = useState(true)

  const { hideModal, updateScreen, screen, system } = props

  const handleSubmit = data => {
    const jsonData = {}
    const Meta = {}

    for (let i = 0; i < Object.keys(data).length; i++) {
      if (
        Object.keys(data)[i] !== 'screenName' &&
        Object.keys(data)[i] !== 'checkedSwitch' &&
        Object.keys(data)[i] !== 'showLogo' &&
        Object.keys(data)[i] !== 'selectedDevice' &&
        Object.keys(data)[i] !== 'indicator'
      ) {
        Meta[Object.keys(data)[i]] = data[Object.keys(data)[i]]
      }

      if (
        Object.keys(data)[i] === 'indicator' &&
        data[Object.keys(data)[i]] !== 'none' &&
        data[Object.keys(data)[i]] !== ''
      ) {
        Meta[data[Object.keys(data)[i]]] = true
      }
    }
    jsonData.Meta = Meta
    jsonData.publicAccess = data.checkedSwitch
    jsonData.showLogo = data.showLogo
    jsonData.name = data.screenName
    const actionData = {
      ScreenId: screen.ScreenId,
      jsonData
    }
    updateScreen(actionData)
    hideModal()
  }

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'auto',
      fontFamily: 'Roboto',
      verticalAlign: 'top',
      maxWidth: '500px'
    }
  })(props => <Paper {...props} />)

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      BackdropProps={{
        classes: {
          root: styles.backdrop
        }
      }}
      PaperComponent={StyledPaper}
    >
      <div className={styles.content}>
        <EditFullScreenViewForm
          {...props}
          systems={system}
          hideModal={hideModal}
          onSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}

EditFullScreenViewContainer.propTypes = {
  submitError: PropTypes.any,
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map)
}

const mapStateToProps = state => ({
  user: getUser(state)
})

const mapActionsToProps = {
  showSnackbar,
  updateScreen
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(EditFullScreenViewContainer)
)
