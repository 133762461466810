import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import styles from './ShowMessageContainer.module.scss'

import ShowMessageForm from '../components/ShowMessageForm'

const ShowMessageContainer = props => {
  const [open] = useState(true)

  const { hideModal, title, text } = props

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <ShowMessageForm
          {...props}
          title={title}
          text={text}
        />
      </div>
    </Modal>
  )
}

ShowMessageContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

export default withLocalize(ShowMessageContainer)
