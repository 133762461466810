// ------------ External imports -------------------- //
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import _ from 'lodash'

// ------------ Material-UI imports -------------------- //
import { Modal } from '@material-ui/core'

// ------------ Internal imports -------------------- //

import { getUser } from 'common/selectors/user'
import { getError, getIsSubmitting } from '../selectors'
import { updateInactivityAlarm } from 'common/actions/systems'
import { resetError } from 'common/actions/error'
import { hideModal } from 'common/actions/modal'
import EditInactivityAlertModalForm from '../components/EditInactivityAlertModalForm/EditInactivityAlertModalForm'
import { addAlertRecipient, deleteAlertRecipient } from 'common/actions/user'
import styles from './EditInactivityAlertModalContainer.module.scss'
import ConfirmationModal from 'modules/modals/ConfirmationModal'

const EditInactivityAlertModalContainer = props => {
  const [open] = useState(true)
  const [confirmingDeleteEmail, setConfirmingDeleteEmail] = useState(null)
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const {
    hideModal,
    updateInactivityAlarm,
    deleteAlertRecipient,
    resetError,
    user,
    systems,
    alarm,
    translate
  } = props
  const systemsByGroup = _.groupBy(systems, 'group')

  const handleSubmit = async data => {
    const emailsSelected = data.emailRecipients
    const jsonData = {}
    const emails = []

    for (let i = 0; i < emailsSelected.length; i++) {
      emails[i] = emailsSelected[i].toLowerCase()
    }

    const userPeriod = { rangeValue: data.rangeValue, rangeUnit: data.rangeUnit }

    jsonData.name = data.alarmName
    jsonData.userPeriod = userPeriod
    jsonData.emailRecipients = emails
    jsonData.active = true
    jsonData.DeviceIds = data.devices
    jsonData.CustomerId = user.toJS()._id
    const actionData = {
      InactivityAlarmId: alarm.InactivityAlarmId,
      jsonData
    }

    updateInactivityAlarm(actionData)
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  const handleDeleteAlertRecipient = email => {
    setConfirmingDeleteEmail(email)
    setConfirmingDelete(true)
  }

  const confirmDeleteAlertRecipient = () => {
    deleteAlertRecipient({
      email: confirmingDeleteEmail,
      userId: user.toJS()._id
    })
    setConfirmingDelete(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <EditInactivityAlertModalForm
            {...props}
            systems={systems}
            systemsByGroup={systemsByGroup}
            hideModal={hideModal}
            onSubmit={handleSubmit}
            onDeleteAlertRecipient={handleDeleteAlertRecipient}
          />
          {confirmingDelete && (
            <ConfirmationModal
              hideModal={() => setConfirmingDelete(false)}
              translate={translate}
              onConfirm={confirmDeleteAlertRecipient}
              contentText={translate('deleteAlertRecipientConfirmationText')}
              contentDetail={confirmingDeleteEmail}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

EditInactivityAlertModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  updateInactivityAlarm,
  addAlertRecipient,
  deleteAlertRecipient,
  resetError,
  hideModal
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(EditInactivityAlertModalContainer)
)
