import * as Routes from 'constants/Routes'
import { injectReducer } from 'common/reducers'

export default store => ({
  path: Routes.FULLSCREEN,
  getComponent: async function (nextState, cb) {
    const [module, reducer] = await Promise.all([
      import('./containers/FullScreenContainer'),
      import('./reducers')
    ])
    injectReducer(store, { key: 'fullscreen', reducer: reducer.default })

    cb(null, module.default)
  }
})
