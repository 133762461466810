import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'
import _ from 'lodash'

import { makeStyles, StylesProvider } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  createMuiTheme,
  TextField,
  InputAdornment,
  ListItem,
  ListItemText,
  Collapse,
  Select,
  MenuItem
} from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeSpanish from 'date-fns/locale/es'
import localeEnglish from 'date-fns/locale/en-US'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { LoadingButton } from '@mui/lab'
import { ThemeProvider } from '@material-ui/styles'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { blue } from '@material-ui/core/colors'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import styles from './DownloadMultipleSettingsForm.module.scss'
import { dataGroupingValues } from 'constants/DataGrouping'
import * as SystemUtils from 'utils/system'

const customTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      dayDisabled: {
        textDecoration: 'line-through'
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          borderColor: 'transparent',
          outline: 'none'
        },
        '&:focus': {
          borderColor: 'transparent',
          outline: 'none'
        }
      }
    }
  }
})

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const DownloadMultipleSettingsForm = props => {
  const { onSubmit, translate, activeLanguage, systems, isSubmitting } = props

  const classes = useStyles()

  const { handleSubmit, register, errors, control, getValues, setError, clearErrors } = useForm()

  const [open, setOpen] = useState({})
  const [filterExpression, setFilterExpression] = useState('')
  const [value, setValue] = useState('csv')
  const [type, setType] = useState([])
  const [selectedDevices, setSelectedDevices] = useState([])
  const [timeIntervalRefValue, setTimeIntervalRefValue] = useState(0)
  const [orderValue, setOrderValue] = useState('0')
  const [groupingValue, setGroupingValue] = useState('10min')
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [daysNumber, setDaysNumber] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [dataState, setDataState] = useState({})
  const [typeChecked, setTypeChecked] = useState([])

  const [checkAllIsClicked, setCheckAllIsClicked] = useState(false)
  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  let typeArray = []
  const systemSelected = system => {
    return selectedDevices.some(selectedId => selectedId === system._id)
  }
  const handleChangeFileExtension = event => {
    setValue(event.target.value)
  }
  const calculateTimeIntervalRefValue = () => {
    let initialRefValue = 0
    systems.forEach(system => {
      if (systemSelected(system) && system.timeInterval > initialRefValue) {
        initialRefValue = system.timeInterval
      }
    })
    const groupeMinute = dataGroupingValues.filter(x => x.minutes >= initialRefValue)
    setGroupingValue(groupeMinute[0].value)
    setTimeIntervalRefValue(initialRefValue)
  }
  // DUPLICATED FUNCTION in DownloadSettingsForm.js
  const dataGroupingItemBuilder = ({ value, translation }) => {
    return (
      <MenuItem
        value={value}
        classes={{
          selected: styles.menuItemSelected
        }}
      >
        {translate(translation)}
      </MenuItem>
    )
  }
  const groupeValue = dataGroupingValues.filter(x => x.value === groupingValue)
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const handleChangeOrder = event => {
    setOrderValue(event.target.value)
  }

  const handleChangeGrouping = event => {
    setGroupingValue(event.target.value)
  }

  const handleChange = event => {
    if (event.target.type === 'checkbox') {
      const { value, checked } = event.target
      setTypeChecked([...typeChecked, value])
      if (!checked) {
        setTypeChecked(typeChecked.filter(item => item !== value))
      }
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams === 0) {
        setError('types', { shouldFocus: 'types' })
      } else {
        clearErrors('types')
      }
    }
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const handleSelectAll = event => {
    setIsCheckAll(!isCheckAll)
    setTypeChecked(type)
    setCheckAllIsClicked(true)
    if (isCheckAll) {
      setTypeChecked([])
    } else {
      clearErrors('types')
    }
  }

  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setSelectedDevices([])
    } else {
      const systemIds = []
      systems.map(s => {
        systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }

  const handleSelectAllGroupDevices = (event, systems) => {
    if (event.target.checked) {
      const aux = []
      for (let i = 0; i < systems.length; i++) {
        if (!selectedDevices.includes(systems[i]._id)) aux.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
    }
  }

  const onChangeDates = () => {
    const diff = moment(getValues('endDate')).diff(moment(getValues('startDate')))
    const diffDuration = moment.duration(diff)
    if (moment(getValues('endDate')) > moment(getValues('startDate'))) {
      if (errors.endDate.message === 'Invalid date') clearErrors('endDate')
    }

    if (Math.trunc(diffDuration.asDays()) + 1 < value1) {
      if (errors.endDate.message === 'Invalid range') clearErrors('endDate')
    }
  }
  let value1

  if (typeChecked.length > 4) {
    value1 = Math.trunc(55479 / ((selectedDevices.length * 24 * 60) / groupeValue[0].minutes)) - 1
  } else {
    value1 = Math.trunc(90643 / ((selectedDevices.length * 24 * 60) / groupeValue[0].minutes)) - 1
  }

  const checkInputs = () => {
    const diff = moment(getValues('endDate')).diff(moment(getValues('startDate')))
    const diffDuration = moment.duration(diff)

    if (moment(getValues('endDate')) < moment(getValues('startDate'))) {
      setError('endDate', { type: 'validate', message: 'Invalid date' })
      return
    }
    if (Math.trunc(diffDuration.asDays()) + 1 > value1) {
      setError('endDate', { type: 'validate', message: 'Invalid range' })
      return
    }

    typeArray = []
    const data = {}
    type.map((t, i) => {
      if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0 && t !== 'weather')
        typeArray.push(t)
      if (t === 'weather') data.weather = getValues(`types[${i}]`).length > 0
      return null
    })
    const values = getValues()
    if (values?.selectedDevices !== undefined && !Array.isArray(values.selectedDevices)) {
      values.selectedDevices = [values.selectedDevices]
    }
    data.devices = values.selectedDevices
    data.params = typeArray
    data.endDate = getValues('endDate')
    data.startDate = getValues('startDate')
    data.order = Number(orderValue)
    data.interval = groupingValue
    data.fileType = value
    setDataState(data)
    onSubmit(data)
  }

  let locale
  if (activeLanguage.code === 'es') locale = localeSpanish
  if (activeLanguage.code === 'en') locale = localeEnglish

  const isIndeterminate = () => {
    return checkAllIsClicked && typeChecked.length > 0 && typeChecked.length !== type.length
  }

  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }

  const onClickNext = () => {
    const values = getValues()
    if (activeTab === 0) {
      if (values && values.selectedDevices !== undefined && values.selectedDevices.length > 0) {
        if (values?.selectedDevices !== undefined && !Array.isArray(values.selectedDevices)) {
          values.selectedDevices = [values.selectedDevices]
        }
        const types = []
        for (let i = 0; i < values.selectedDevices.length; i++) {
          const system = systems.filter(s => {
            return s._id === values.selectedDevices[i]
          })[0]
          const sortedData = SystemUtils.getDataSorted(system)
          sortedData.map(d => {
            if (types.indexOf(d.type) === -1) types.push(d.type)
            return null
          })
          if (
            types.indexOf('weather') === -1 &&
            system.weather &&
            system.weather.baseStationLocation
          )
            types.push('weather')
        }
        setType(types)
        setActiveTab(1)
      } else {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }
    } else if (activeTab === 1) {
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams !== 0) {
        calculateTimeIntervalRefValue()

        setActiveTab(2)
      } else {
        setError('types', { shouldFocus: 'types' })
      }
    }
  }

  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }

  const handleFilterExpressionChange = event => {
    setFilterExpression(event.target.value)
  }

  const onClickBack = () => {
    if (activeTab === 2) {
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }

  const onClickDownload = () => {
    checkInputs()
  }

  let systemsJS = []

  if (filterExpression !== '') {
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(filterExpression.toLowerCase()) !== -1
    })
  } else {
    systemsJS = systems
  }
  const systemsGroup = _.groupBy(systemsJS, 'group')

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('downloadMultipleData')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div className={styles.firstPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('navParams')}
                  </Typography>
                  <Typography className={styles.subheaderDateAndFormat}>
                    {translate('multipleNavDateAndFormat')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={handleFilterExpressionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('searchDevice')}
                  inputProps={{ maxLength: 32 }}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.alarmName
                  })}
                />
                <div className={styles.subInfoWrapper}>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectDevices')}
                  </Typography>
                </div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAllDevices'
                      id='selectAllDevices'
                      indeterminate={isIndeterminateDevices()}
                      onChange={handleSelectAllDevices}
                      checked={isCheckAllDevices}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <Paper
                  className={styles.devicesListPaper}
                  classes={{ root: classes.paper }}
                >
                  <List
                    disableTouchRipple
                    className={styles.devicesList}
                  >
                    {_.map(systemsGroup, function (systems, group) {
                      const systemsType = _.groupBy(systems, 'micaType')
                      return (
                        <div className={styles.noGroupWrapper}>
                          {group !== 'undefined' && (
                            <div>
                              <ListItem
                                button
                                disableTouchRipple
                                className={styles.groupListItem}
                              >
                                {!open[group] ? (
                                  <ExpandLess
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                ) : (
                                  <ExpandMore
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                )}
                                <Checkbox
                                  className={cx(styles.checkbox)}
                                  classes={{ checked: styles.checkedBox }}
                                  name={group}
                                  onChange={e => handleSelectAllGroupDevices(e, systems)}
                                  id={group}
                                />
                                <ListItemText
                                  primary={group}
                                  className={styles.listItemText}
                                />
                              </ListItem>
                            </div>
                          )}
                          {group === 'undefined' && (
                            <div>
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div className={styles.typeRowWrapper}>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={type}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={system._id}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </div>
                          )}

                          {group !== 'undefined' && (
                            <Collapse
                              in={!open[group]}
                              timeout='auto'
                              unmountOnExit
                              classes={{
                                wrapper: classes.wrapper
                              }}
                            >
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div className={styles.typeRowWrapper}>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={translate(type)}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={index + 'systemFormControlLabelCheckbox'}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </Collapse>
                          )}
                        </div>
                      )
                    })}
                  </List>
                </Paper>
                {errors.selectedDevices && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyDeviceRequired')}</small>
                  </div>
                )}
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div className={styles.firstPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevices}>
                    {translate('navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParamsSelected}>
                    {translate('navParams')}
                  </Typography>
                  <Typography className={styles.subheaderDateAndFormat}>
                    {translate('multipleNavDateAndFormat')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectParameters')}
                  </Typography>
                </div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAll'
                      id='selectAll'
                      indeterminate={isIndeterminate()}
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <div className={styles.checkboxWrapper}>
                  {type.map((dataType, i) => {
                    return (
                      <div
                        key={i + 'typeCheckbox'}
                        className={styles.checkboxFormDiv}
                      >
                        <FormControlLabel
                          className={styles.checkboxForm}
                          control={
                            <Checkbox
                              className={cx(styles.checkbox)}
                              classes={{ checked: styles.checkedBox }}
                              onChange={handleChange}
                              checked={typeChecked.includes(dataType)}
                              required
                              name={`types[${i}]`}
                              value={dataType}
                              inputRef={register()}
                              disableRipple
                            />
                          }
                          label={translate(dataType)}
                        />
                      </div>
                    )
                  })}
                </div>
                {errors.types && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyFieldRequired')}</small>
                  </div>
                )}
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevices}>
                    {translate('navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('navParams')}
                  </Typography>
                  <Typography className={styles.subheaderDateAndFormatSelected}>
                    {translate('multipleNavDateAndFormat')}
                  </Typography>
                </div>
                <div className={styles.dataGroupingWrapper}>
                  <Typography className={styles.selectLabel}>
                    {translate('downloadDataForm.dataGrouping')}
                  </Typography>
                  <FormControl>
                    <Select
                      id='dataGrouping'
                      value={groupingValue}
                      defaultValue={groupingValue}
                      onChange={handleChangeGrouping}
                      variant='standard'
                      disableUnderline
                      className={styles.select}
                      IconComponent={KeyboardArrowDownIcon}
                      classes={{
                        icon: styles.selectIcon,
                        select: styles.selectFocus
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {dataGroupingValues.map(item => {
                        if (timeIntervalRefValue <= item.minutes) {
                          return dataGroupingItemBuilder(item)
                        }
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectDateInterval')}
                  </Typography>
                  <div className={styles.subInfoInterval}>
                    <span>{translate('downloadDataForm.disponible')} </span>
                    <span>{value1 !== daysNumber ? setDaysNumber(value1) : value1}</span>
                    <span>{translate('downloadDataForm.days')} </span>

                    <span className={styles.hover_visible}>
                      <img
                        src='../images/info.svg'
                        className={cx('img_info', styles.infoIcon)}
                        alt=''
                      />
                      <div className={styles.information_box}>
                        <div className={styles.information}>
                          <h4 className={styles.indicatorInformationTitle}>
                            {translate('availability')}
                          </h4>
                          <p className='mb-3 mt-2'>{translate('availabilityText')}</p>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className={styles.datePickerWrapper}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                  >
                    <div className={styles.picker}>
                      <Typography
                        variant='subtitle2'
                        className={styles.subInfo}
                      >
                        {translate('startDate')}
                      </Typography>
                      <ThemeProvider theme={customTheme}>
                        <Controller
                          as={
                            <KeyboardDatePicker
                              InputProps={{ readOnly: true }}
                              disableToolbar
                              disableFuture
                              // shouldDisableDate={disableDates}
                              variant='inline'
                              margin='0'
                              inputRef={register()}
                              autoOk
                              name='startDatePicker'
                              className={cx(styles.datePicker, {
                                [styles.hasError]: errors.startDate
                              })}
                              format='dd/MM/yyyy'
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              onChange={onChangeDates}
                            />
                          }
                          control={control}
                          name='startDate'
                          defaultValue={moment}
                          rules={{ required: true }}
                        />
                      </ThemeProvider>
                    </div>
                    {errors.startDate && (
                      <div className={styles.error}>
                        <small>{translate('validation.required')}</small>
                      </div>
                    )}
                    <div className={styles.picker}>
                      <Typography
                        variant='subtitle2'
                        className={styles.subInfo}
                      >
                        {translate('endDate')}
                      </Typography>
                      <ThemeProvider theme={customTheme}>
                        <Controller
                          as={
                            <KeyboardDatePicker
                              InputProps={{ readOnly: true }}
                              disableToolbar
                              disableFuture
                              // shouldDisableDate={disableDates}
                              variant='inline'
                              inputRef={register()}
                              format='dd/MM/yyyy'
                              autoOk
                              margin='0'
                              name='endDatePicker'
                              className={cx(styles.datePicker, {
                                [styles.hasError]: errors.endDate
                              })}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              onChange={onChangeDates}
                            />
                          }
                          control={control}
                          name='endDate'
                          defaultValue={moment}
                          rules={{ required: true, validate: checkInputs }}
                        />
                      </ThemeProvider>
                    </div>
                    {errors.endDate && errors.endDate?.type === 'required' && (
                      <div className={styles.error}>
                        <small>{translate('validation.required')}</small>
                      </div>
                    )}
                    {errors.endDate &&
                      errors.endDate?.type === 'validate' &&
                      errors.endDate?.message === 'Invalid date' && (
                        <div className={styles.error}>
                          <small>{translate('validation.invalidDateRange')}</small>
                        </div>
                      )}
                    {errors.endDate &&
                      errors.endDate?.type === 'validate' &&
                      errors.endDate?.message === 'Invalid range' && (
                        <div className={styles.error}>
                          <small>{translate('validation.invalidDateInterval')}</small>
                        </div>
                      )}
                  </MuiPickersUtilsProvider>
                </div>
                {selectedDevices.length <= 1 && (
                  <div>
                    <div className={styles.fileExtensionWrapper}>
                      <Typography className={styles.subInfo}>
                        {translate('downloadDataForm.downloadFormat')}
                      </Typography>
                    </div>
                    <div className={styles.fileExtensionWrapper}>
                      <Typography className={styles.selectLabel}>
                        {translate('downloadDataForm.downloadExtension')}
                      </Typography>
                      <FormControl>
                        <Select
                          id='extensionFileSelect'
                          value={value}
                          onChange={handleChangeFileExtension}
                          variant='standard'
                          disableUnderline
                          className={styles.select}
                          IconComponent={KeyboardArrowDownIcon}
                          classes={{
                            icon: styles.selectIcon,
                            select: styles.selectFocus
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <MenuItem
                            value='csv'
                            classes={{
                              selected: styles.menuItemSelected
                            }}
                          >
                            {translate('csv')}
                          </MenuItem>
                          <MenuItem
                            value='xlsx'
                            classes={{
                              selected: styles.menuItemSelected
                            }}
                          >
                            {translate('xlsx')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}
                <div className={styles.fileExtensionWrapperOrder}>
                  <Typography className={styles.selectLabel}>
                    {translate('downloadDataForm.downloadOrder')}
                  </Typography>
                  <FormControl>
                    <Select
                      id='orderSelect'
                      value={orderValue}
                      onChange={handleChangeOrder}
                      variant='standard'
                      disableUnderline
                      className={styles.select}
                      IconComponent={KeyboardArrowDownIcon}
                      classes={{
                        icon: styles.selectIcon,
                        select: styles.selectFocus
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      <MenuItem
                        value='0'
                        classes={{
                          selected: styles.menuItemSelected
                        }}
                      >
                        {translate('downloadDataForm.olderDataFirst')}
                      </MenuItem>
                      <MenuItem
                        value='1'
                        classes={{
                          selected: styles.menuItemSelected
                        }}
                      >
                        {translate('downloadDataForm.latestDataFirst')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    fullWidth
                    loading={isSubmitting}
                    loadingPosition='end'
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickDownload}
                  >
                    {translate('downloadMultipleData')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

DownloadMultipleSettingsForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(DownloadMultipleSettingsForm)
