import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { getUser } from 'common/selectors/user'
import { hideModal } from '../../../../common/actions/modal'
import { updateSystemGlobalLights, updateSystemLights } from '../../../../common/actions/systems'
import { getError, getIsSubmitting } from '../selectors'
import { resetError } from 'common/actions/error'
import EditSystemLightsForm from '../components/EditSystemLightsForm/EditSystemLightsForm'
import styles from './EditSystemLightsContainer.module.scss'

const EditSystemLightsContainer = props => {
  const [open] = useState(true)

  const { hideModal, system, updateSystemGlobalLights, updateSystemLights, resetError, user } =
    props

  const handleSubmit = async data => {
    if (data.selectAll) {
      updateSystemGlobalLights({
        CustomerId: user.toJS()._id,
        ledOn: data.checkedLights
      })
    } else {
      updateSystemLights({
        _id: system._id,
        ledOn: data.checkedLights
      })
    }
  }

  const initialValues = {
    ledOn: system.ledOn
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <EditSystemLightsForm
          {...props}
          initialValues={initialValues}
          hideModal={hideModal}
          onSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  updateSystemLights,
  updateSystemGlobalLights,
  hideModal,
  resetError
}

EditSystemLightsContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapActionsToProps)(EditSystemLightsContainer)
