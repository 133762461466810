import { createAction } from 'utils'

export const RESET_ERROR = '@error/RESET_ERROR'

export const constants = {
  RESET_ERROR
}

export const resetError = createAction(RESET_ERROR)

export default {
  resetError
}
