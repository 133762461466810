import PropTypes from 'prop-types'
import styles from './PublicLayout.module.scss'

import AppLayout from '../AppLayout'
import Header from '../../Header'
import Footer from '../../Footer'
import AuxHeader from '../../AuxHeader/AuxHeader'

const PublicLayout = props => {
  return (
    <div className={styles.component}>
      <AuxHeader isLoggedIn={false} />
      <Header isLoggedIn={false} />
      <div className={styles.content}>{props.children}</div>
      <Footer />
    </div>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node
}

export default PublicLayout
