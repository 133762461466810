import QRCode from 'qrcode.react'

import { URL } from '../../../../config'
import * as Routes from 'constants/Routes'
import styles from './QRContainer.module.scss'
import { Translate } from 'react-localize-redux'

const QRContainer = ({ translate, screenId }) => {
  const getScreenQRURL = () => {
    return `https://www.${URL}${Routes.FULLSCREEN_LINK}/${screenId}`
  }
  return (
    <div
      className='background_shadow text-center h-100'
      style={{ padding: '10px' }}
    >
      <p className={styles.qrText}>
        <Translate
          id='fullScreenPage.knowIaq'
          options={{ renderInnerHtml: true }}
        />
      </p>
      <QRCode
        id='code'
        value={getScreenQRURL()}
        renderAs='canvas'
        className={styles.qrContainer}
        level={'H'}
      />
    </div>
  )
}

export default QRContainer
