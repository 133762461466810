import { useState, useEffect } from 'react'
import moment from 'moment'
import styles from './Clock.module.scss'
import Languages from 'constants/Languages'

const Clock = lang => {
  const [clock, setClock] = useState({
    date: null,
    time: null
  })

  useEffect(() => {
    tick()
    const interval = setInterval(tick, 60000)
    return function cleanup() {
      clearInterval(interval)
    }
  }, [])

  const tick = () => {
    setClock({
      date:
        lang.lang === Languages[0].code
          ? moment().format('DD/MM/YYYY')
          : moment().format('MM/DD/YYYY'),
      time: moment().format('HH:mm a')
    })
  }

  return (
    <div className='time_date background_shadow text-center h-100'>
      <div style={{ height: '100%', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <div style={{ width: '100%' }}>
          <h2 className={styles.fontDigit}>{clock.time}</h2>
          <h6 className={styles.fontDate}>{clock.date}</h6>
        </div>
      </div>
    </div>
  )
}

export default Clock
