// Temperature possible units
export const KELVIN = 'KELVIN'
export const CELSIUS = 'CELSIUS'
export const FAHRENHEIT = 'FAHRENHEIT'

// TVOCs possible units
export const VOCS_INDEX = 'VOCS_INDEX'
export const VOCS_UG_WELL = 'VOCS_UG_WELL'
export const VOCS_UG_RESET = 'VOCS_UG_RESET'
export const VOCS_PPB = 'VOCS_PPB'

// Formaldehyde possible units
export const FORMALDEHYDE_UG = 'FORMALDEHYDE_UG'
export const FORMALDEHYDE_PPB = 'FORMALDEHYDE_PPB'

// O3 possible units
export const O3_PPB = 'O3_PPB'
export const O3_UG = 'O3_UG'

// CO possible units
export const CO_PPM = 'CO_PPM'
export const CO_MG = 'CO_MG'

// NO2 possible units
export const NO2_PPB = 'NO2_PPB'
export const NO2_UG = 'NO2_UG'

export default {
  KELVIN,
  CELSIUS,
  FAHRENHEIT,
  VOCS_INDEX,
  VOCS_UG_WELL,
  VOCS_UG_RESET,
  VOCS_PPB,

  FORMALDEHYDE_UG,
  FORMALDEHYDE_PPB,

  O3_PPB,
  O3_UG,

  CO_PPM,
  CO_MG,

  NO2_PPB,
  NO2_UG
}
