import { useState } from 'react'
import PropTypes from 'prop-types'

import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import { showSnackbar } from '../../../../common/actions/snackbar'
import styles from './NewGroupContainer.module.scss'
import { connect } from 'react-redux'
import NewGroupForm from '../components/NewGroupForm/NewGroupForm'
import { createGroup } from '../../../../common/actions/systems'
import { getError, getIsSubmitting } from '../selectors'

const NewGroupContainer = props => {
  const [open] = useState(true)

  const { hideModal, createGroup } = props

  const handleSubmit = data => {
    createGroup(data)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
      >
        <div className={styles.content}>
          <NewGroupForm
            {...props}
            onSubmit={handleSubmit}
            onCancel={hideModal}
          />
        </div>
      </Modal>
    </div>
  )
}

NewGroupContainer.propTypes = {
  submitError: PropTypes.any,
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  createGroup,
  showSnackbar
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(NewGroupContainer))
