import { connect } from 'react-redux'
import { getUser, getUserLang } from '../../selectors/user'
import { getActiveLanguage, getLanguages, getTranslate, withLocalize } from 'react-localize-redux'
import { hideModal, showModal } from '../../actions/modal'
import { useEffect, useState } from 'react'
import { AlternativeSupport } from './AlternativeSupport/AlternativeSupport'

const SupportButton = props => {
  const [beaconLoaded, setBeaconLoaded] = useState(false)
  const [beaconBlocked, setBeaconBlocked] = useState(false)
  const { translate, user } = props
  const { language } = user.toJS()
  useEffect(() => {
    let beaconTimeout

    const loadBeacon = () => {
      ;(function (e, t, n) {
        function a() {
          var e = t.getElementsByTagName('script')[0]
          var n = t.createElement('script')
          n.type = 'text/javascript'
          n.async = true
          n.src = 'https://beacon-v2.helpscout.net'
          n.onload = () => {
            setBeaconLoaded(true) // Marcamos que el script se ha cargado
          }
          n.onerror = () => {
            setBeaconBlocked(true) // Marcamos que el script fue bloqueado o no se pudo cargar
          }
          e.parentNode.insertBefore(n, e)
        }
        if (
          ((e.Beacon = n =
            function (t, n, a) {
              e.Beacon.readyQueue.push({ method: t, options: n, data: a })
            }),
          (n.readyQueue = []),
          t.readyState === 'complete')
        )
          return a()
        e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, false)
      })(window, document, window.Beacon || function () {})

      window.Beacon(
        'init',
        language === 'en'
          ? 'ba1f6d2a-b28d-4296-ad43-d4dbc62c1644'
          : '77dc728b-27bc-49aa-8b44-91ef89938bd5'
      )

      beaconTimeout = setTimeout(() => {
        if (!beaconLoaded) {
          setBeaconBlocked(true) // Si el script no se ha cargado después de un tiempo, lo marcamos como bloqueado
        }
      }, 3000) // Puedes ajustar este tiempo según tus necesidades
    }

    loadBeacon()

    return () => {
      clearTimeout(beaconTimeout)
      window.Beacon('destroy')
    }
  }, [beaconLoaded])

  if (beaconBlocked) {
    return <AlternativeSupport translate={translate} />
  }

  return null // Este componente no necesita renderizar nada en la interfaz de usuario si Beacon se carga correctamente
}

const mapStateToProps = state => ({
  user: getUser(state),
  userLanguage: getUserLang(state),
  activeLanguage: getActiveLanguage(state.localize),
  languages: getLanguages(state.localize),
  translate: getTranslate(state.localize)
})

const mapActionsToProps = {
  showModal,
  hideModal
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(SupportButton))
