import request from 'utils/request'

export function getFullScreenById(id) {
  return request(`fullscreens/${id}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function getChannelById(id) {
  return request(`fullscreens/shareable/${id}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function getFullScreenMeasurementsByTypeNew(data) {
  return request(`fullscreens/mtype`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function getFullScreenUser(id) {
  return request(`fullscreens/${id}/user`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function getFullScreenMeasuresByType(data) {
  return request(`fullscreens/mtype`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}
