import _ from 'lodash'
import moment from 'moment'
import DataTypes from 'constants/DataTypes'
import * as DataUtils from 'utils/data'

const order = [
  DataTypes.COVID_19,
  DataTypes.VENTILATION_INDICATOR,
  DataTypes.THERMAL_INDICATOR,
  DataTypes.VENTILATION_MODE,
  DataTypes.TEMPERATURE,
  DataTypes.HUMIDITY,
  DataTypes.CO2,
  DataTypes.FORMALDEHYDE,
  DataTypes.VOCS,
  DataTypes.VOCS_INDEX,
  DataTypes.IAQ,
  DataTypes.PM1,
  DataTypes.PM25,
  DataTypes.PM4,
  DataTypes.PM10,
  DataTypes.CO,
  DataTypes.O3,
  DataTypes.RADON,
  DataTypes.RADIANT_TEMPERATURE,
  DataTypes.NO2,
  DataTypes.NOX,
  DataTypes.NOISE,
  DataTypes.LIGHT,
  DataTypes.PRESSURE_PIP,
  DataTypes.OSC_RATE,
  DataTypes.RATE_MIN,
  DataTypes.VOLUME,
  DataTypes.EI,
  DataTypes.P_TRIGGER
]

const sortDataByOrder = data => _.sortBy(data, obj => _.indexOf(order, obj.type))

export const getDataSorted = (system, filter = []) => {
  return sortDataByOrder(system.data).filter(data => {
    return !filter.includes(data.type)
  })
}

// export const getNoDustDataSorted = (system) => {
//   return sortDataByOrder(system.data).filter((data) => {
//     return !DataTypes.DUST.includes(data.type)
//   })
// }
//
// export const getDustData = (system) => {
//   return system.data.filter((data) => {
//     return DataTypes.DUST.includes(data.type)
//   })
// }

const newRow = {
  temperature: null,
  humidity: null,
  co2: null,
  formaldehyde: null,
  vocs: null,
  pm25: null,
  measurementDate: null,
  measurementTime: null,
  outdoorTemperature: null,
  outdoorHumidity: null,
  outdoorTemperatureDate: null,
  outdoorTemperatureTime: null
}

const MOMENT_FORMAT_DATE = 'DD/MM/YYYY'
const MOMENT_FORMAT_TIME = 'HH:mm:ss'

export const systemDataToCSV = system => {
  const rows = []
  const headerLabel = []
  const typeMeasurement = []

  // Create data rows for all data measurements grouped by date
  system.data.forEach(data => {
    const { type, measurements, settings } = data
    const unit = DataUtils.getDataUnit(type, settings)
    headerLabel.push(`${type} (${unit})`)
    typeMeasurement.push(type)

    /*
    if (!columns.find(h => h.label === headerLabel)) {
      columns.push({
        label: headerLabel
      })
    }
    */

    measurements.forEach(measurement => {
      if (!measurement) return
      const { value, date } = measurement
      const rowIndex = rows.findIndex(r => r.date === date)

      if (rowIndex === -1) {
        rows.push({
          ...newRow,
          measurementDate: moment(date).format(MOMENT_FORMAT_DATE),
          measurementTime: moment(date).format(MOMENT_FORMAT_TIME),
          [type]: value,
          date // <-- is not shown in CSV file but only used to group the data by date
        })
      } else {
        rows[rowIndex] = {
          ...rows[rowIndex],
          [type]: value
        }
      }
    })
  })

  // Include data & time header
  headerLabel.push('Date')
  headerLabel.push('Time')
  typeMeasurement.push('measurementDate')
  typeMeasurement.push('measurementTime')
  // Include weather columns
  /*
  const weatherUnit = DataUtils.getDataUnit(DataTypes.WEATHER, system.weatherSettings)
  const weatherHeaderLabel = `${DataTypes.WEATHER} (${weatherUnit})`
  const outdoorHumidityHeaderLabel = `Outdoor Humidity (%)`

  headerLabel.push('')
  headerLabel.push(weatherHeaderLabel)
  headerLabel.push(outdoorHumidityHeaderLabel)
  headerLabel.push('Date')
  headerLabel.push('Time')
  typeMeasurement.push('')
  typeMeasurement.push('outdoorTemperature')
  typeMeasurement.push('outdoorHumidity')
  typeMeasurement.push('outdoorTemperatureDate')
  typeMeasurement.push('outdoorTemperatureTime')
  */

  // Add weather data to the rows
  if (system.weather) {
    system.weather.measurements.forEach((measurement, index) => {
      const { temp, humidity, dateTime } = measurement
      if (rows[index]) {
        rows[index] = {
          ...rows[index],
          outdoorTemperature: temp,
          outdoorHumidity: humidity,
          outdoorTemperatureDate: moment(dateTime).format(MOMENT_FORMAT_DATE),
          outdoorTemperatureTime: moment(dateTime).format(MOMENT_FORMAT_TIME)
        }
      } else {
        rows.push({
          ...newRow,
          outdoorTemperature: temp,
          outdoorHumidity: humidity,
          outdoorTemperatureDate: moment(dateTime).format(MOMENT_FORMAT_DATE),
          outdoorTemperatureTime: moment(dateTime).format(MOMENT_FORMAT_TIME)
        })
      }
    })
  }

  return {
    rows,
    headerLabel,
    typeMeasurement
  }
}
