import DataTypes from './DataTypes'
import MeasurementUnits from './MeasurementUnits'

export default {
  [DataTypes.TEMPERATURE]: '°C',
  [DataTypes.RADIANT_TEMPERATURE]: '°C',
  [DataTypes.HUMIDITY]: '%',
  [DataTypes.CO2]: 'ppm',
  [DataTypes.FORMALDEHYDE]: 'µg/m³',
  [DataTypes.VOCS]: 'ppb',
  [DataTypes.VOCS_INDEX]: 'Index',
  [DataTypes.PM25]: 'µg/m³',
  [DataTypes.PM10]: 'µg/m³',
  [DataTypes.PM4]: 'µg/m³',
  [DataTypes.PM1]: 'µg/m³',
  [DataTypes.CO]: 'ppm',
  [DataTypes.O3]: 'ppb',
  [DataTypes.RADON]: 'Bq/m³',
  [DataTypes.COVID_19]: '',
  [DataTypes.PRESSURE]: 'hPa',
  [DataTypes.PRESSURE_PIP]: 'cmH₂O',
  [DataTypes.OSC_RATE]: 'Resp/min',
  [DataTypes.RATE_MIN]: 'Resp/min',
  [DataTypes.VOLUME]: 'cc',
  [DataTypes.P_TRIGGER]: 'cmH₂O',
  [DataTypes.LIGHT]: 'lux',
  [DataTypes.NOISE]: 'dB',
  [DataTypes.NO2]: 'ppb',
  [DataTypes.NOX]: '',
  [DataTypes.CAPACITY]: 'pax',

  // [DataTypes.FORMALDEHYDE]: 'µg/m &#179;',
  // [DataTypes.FORMALDEHYDE]: `µg/m` + '&#13221;',

  [MeasurementUnits.CELSIUS]: '°C',
  [MeasurementUnits.KELVIN]: 'K',
  [MeasurementUnits.FAHRENHEIT]: '°F',

  [MeasurementUnits.VOCS_INDEX]: 'Index',
  [MeasurementUnits.VOCS_UG_WELL]: 'µg/m³',
  [MeasurementUnits.VOCS_UG_RESET]: 'µg/m³',
  [MeasurementUnits.VOCS_PPB]: 'ppb',

  [MeasurementUnits.FORMALDEHYDE_UG]: 'µg/m³',
  [MeasurementUnits.FORMALDEHYDE_PPB]: 'ppb',

  [MeasurementUnits.O3_PPB]: 'ppb',
  [MeasurementUnits.O3_UG]: 'µg/m³',

  [MeasurementUnits.CO_PPM]: 'ppm',
  [MeasurementUnits.CO_MG]: 'mg/m³',

  [MeasurementUnits.NO2_PPB]: 'ppb',
  [MeasurementUnits.NO2_UG]: 'ug/m³'
}
