import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { LocalizeProvider } from 'react-localize-redux'
import { applyRouterMiddleware, Router } from 'react-router'
import createRoutes from 'modules/router'

const Root = ({ store, history }) => (
  <Provider store={store}>
    <LocalizeProvider store={store}>
      <Router
        render={applyRouterMiddleware()}
        history={history}
        onUpdate={() => window.scrollTo(0, 0)}
        routes={createRoutes(store)}
      />
    </LocalizeProvider>
  </Provider>
)

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
}

export default Root
