import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const INIT_LOCALIZE = '@localize/INIT_LOCALIZE'
export const SET_ACTIVE_LANGUAGE = '@localize/SET_ACTIVE_LANGUAGE'

export const SET_ACTIVE_LANGUAGE_REQUEST = createRequestTypes('SET_ACTIVE_LANGUAGE_REQUEST')

export const constants = {
  INIT_LOCALIZE,
  SET_ACTIVE_LANGUAGE,

  SET_ACTIVE_LANGUAGE_REQUEST
}

export const initLocalize = createAction(INIT_LOCALIZE)
export const setActiveLanguage = createAction(SET_ACTIVE_LANGUAGE)

export const setActiveLanguageRequest = createRequestAction(SET_ACTIVE_LANGUAGE_REQUEST)

export default {
  initLocalize,
  setActiveLanguage,

  setActiveLanguageRequest
}
