export const dataGroupingDefault = {
  value: 'c',
  minutes: 0,
  translation: 'downloadDataForm.originalData'
}

export const dataGroupingValues = [
  {
    value: '10min',
    minutes: 10,
    translation: 'downloadDataForm.grouping10'
  },
  {
    value: '15min',
    minutes: 15,
    translation: 'downloadDataForm.grouping15'
  },
  {
    value: '30min',
    minutes: 30,
    translation: 'downloadDataForm.grouping30'
  },
  {
    value: '1H',
    minutes: 60,
    translation: 'downloadDataForm.grouping1'
  }
]
