import { createAction } from 'utils'

export const TOGGLE_LOADER = '@loader/TOGGLE_LOADER'

export const constants = {
  TOGGLE_LOADER
}

export const toggleLoader = createAction(TOGGLE_LOADER)

export default {
  toggleLoader
}
