// ------------ External imports -------------------- //
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize, getActiveLanguage } from 'react-localize-redux'
import { connect } from 'react-redux'

// ------------ Material-UI imports -------------------- //
import { Modal } from '@material-ui/core'

// ------------ Internal imports -------------------- //
import _ from 'lodash'

import { resetError } from 'common/actions/error'

import { getUser } from 'common/selectors/user'
import { getError, getIsSubmitting } from '../selectors'
import { updateSystemWeather } from 'common/actions/systems'
import { hideModal } from 'common/actions/modal'
import { addAlertRecipient, deleteAlertRecipient } from 'common/actions/user'
import styles from './WeatherModalContainer.module.scss'
import WeatherModal from '../components/WeatherModalForm'
const WeatherModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, system, systems, resetError, updateSystemWeather, isSubmitting } = props
  const systemsByGroup = _.groupBy(systems, 'group')
  const handleSubmit = data => {
    const jsonData = {}
    if (data.country) {
      jsonData.country = data.country
      jsonData.zipCode = data.zipCode
    } else {
      jsonData.lat = data.location.latitude.toFixed(4)
      jsonData.lon = data.location.longitude.toFixed(4)
      jsonData.address = data.address
    }
    jsonData.devices = data.devices

    updateSystemWeather(jsonData)
  }
  useEffect(() => {
    resetError()
  }, [resetError])

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <WeatherModal
            systemsByGroup={systemsByGroup}
            {...props}
            system={system}
            systems={systems}
            activeLanguage={props.activeLanguage}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </div>
      </Modal>
    </div>
  )
}
WeatherModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  submitError: PropTypes.any,
  activeLanguage: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  resetError: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state),
  activeLanguage: getActiveLanguage(state.localize)
})

const mapActionsToProps = {
  addAlertRecipient,
  deleteAlertRecipient,
  resetError,
  updateSystemWeather,
  hideModal
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(WeatherModalContainer))
