import styles from './NewIndicators.module.scss'
import { ReactComponent as Icon } from 'icons/circle_thin.svg'
const NewIndicator = ({ numericValue }) => {
  let values
  if (numericValue > 29 && numericValue < 33) {
    values = 29
  } else if (numericValue > 63 && numericValue < 66) {
    values = 63
  } else {
    values = numericValue
  }

  const minimumValue = 33
  const maximumValue = 66
  const redColor = '#FF3152'
  const yellowColor = '#FFA800'
  const greenColor = '#05CE91'
  const isRedColor = value => {
    return value < minimumValue
  }
  const isYellowColor = value => {
    return value >= minimumValue && value < maximumValue
  }
  const angle = (values * 360) / 100

  let color
  if (isRedColor(numericValue)) {
    color = redColor
  } else if (isYellowColor(numericValue)) {
    color = yellowColor
  } else {
    color = greenColor
  }

  let radius = 47.5

  const circumference = 2 * Math.PI * radius

  const strokeDasharray = (angle / 360) * circumference
  return (
    <div className={styles.progress_chart}>
      <Icon className={styles.spaces} />

      <svg className={styles.progress_chart__circle}>
        <circle
          className={styles.progress_chart__arc3}
          cx='50%'
          cy='50%'
          r='47.5%'
          strokeLinecap='round'
          strokeDasharray={[strokeDasharray + '%', circumference - strokeDasharray + '%']}
          strokeDashoffset={0}
          stroke={color}
        />
      </svg>
      <div className={styles.progress_chart__percentage}>
        <div className={styles.indicatorBox}>
          <h1>{numericValue}</h1>
        </div>
      </div>
    </div>
  )
}
export default NewIndicator
