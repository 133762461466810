import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { getUser } from 'common/selectors/user'
import { getIsSubmitting, getError } from '../selectors'

import { Modal } from '@material-ui/core'
import { addCollaborativeAccount, editCollaborativeAccount } from 'common/actions/user'
import styles from './AddCollaborativeModalContainer.module.scss'
import AddCollaborativeModalForm from '../components/AddCollaborativeModalForm'
import { resetError } from 'common/actions/error'

const AddCollaborativeModalContainer = props => {
  const [open] = useState(true)
  const {
    hideModal,
    edit,
    email,
    user,
    addCollaborativeAccount,
    editCollaborativeAccount,
    isSubmitting,
    resetError
  } = props

  const { collaborators, _id } = user.toJS()

  const handleEdit = data => {
    data.userId = _id
    data.mainEmail = user.toJS().email.toLowerCase()
    editCollaborativeAccount(data)
  }

  const handleAdd = data => {
    data.confirmed = false
    data.userId = _id
    data.mainEmail = user.toJS().email.toLowerCase()
    addCollaborativeAccount(data)
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <AddCollaborativeModalForm
            {...props}
            isSubmitting={isSubmitting}
            edit={edit}
            email={email}
            collaborators={collaborators}
            onSubmit={edit ? handleEdit : handleAdd}
          />
        </div>
      </Modal>
    </div>
  )
}

AddCollaborativeModalContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  isSubmitting: getIsSubmitting(state),
  submitError: getError(state)
})

const mapActionsToProps = {
  editCollaborativeAccount,
  addCollaborativeAccount,
  resetError
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(AddCollaborativeModalContainer)
)
