import { useState } from 'react'
import PropTypes from 'prop-types'

import { withLocalize } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import { Modal, Button } from '@material-ui/core'
import styles from './UploadFileContainer.module.scss'

import { showSnackbar } from '../../../../common/actions/snackbar'
import { getIsSubmitting, getError } from '../selectors'

import { connect } from 'react-redux'
import { getUser } from '../../../../common/selectors/user'
import { LoadingButton } from '@mui/lab'
import Typography from '@material-ui/core/Typography'
import { deleteUserLogo, uploadUserLogo } from '../../../../common/actions/user'
import { Alert } from '@material-ui/lab'
import ConfirmationModal from '../../ConfirmationModal'

const UploadFileContainer = props => {
  const [open] = useState(true)
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [fileSelected, setFileSelected] = useState(false)
  const [uploadedFile, setUploadedFile] = useState(null)

  const { hideModal, translate, isSubmitting, submitError, uploadUserLogo, user, deleteUserLogo } =
    props

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      fontFamily: 'Roboto',
      verticalAlign: 'top',
      maxWidth: '500px'
    }
  })

  const resizeImage = (width, uploadedFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.readAsDataURL(uploadedFile)

      reader.onload = event => {
        const img = new Image()
        img.src = event.target.result.toString()

        img.onload = () => {
          const elem = document.createElement('canvas')
          const scaleFactor = width / img.width
          elem.width = width
          elem.height = img.height * scaleFactor

          const ctx = elem.getContext('2d')
          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor)

          ctx.canvas.toBlob(
            blob => {
              resolve(
                new File([blob], user._id, {
                  type: uploadedFile.type,
                  lastModified: Date.now()
                })
              )
            },
            uploadedFile.type,
            1
          )
        }
      }
    })
  }

  const isNotAnImage = type => {
    return type.split('/')[0] !== 'image'
  }

  const handleClick = async event => {
    event.preventDefault()
    if (!isNotAnImage(uploadedFile.type)) {
      const resizedImage = await resizeImage(596, uploadedFile)
      const reader = new FileReader()
      reader.readAsBinaryString(resizedImage)
      reader.onload = function () {
        const data = {
          extension: resizedImage.type,
          image: btoa(reader.result)
        }
        uploadUserLogo(data)
      }
      reader.onerror = function () {}
    }
  }

  const error = () => {
    if (!submitError) return null

    let message = translate('submitErrors.unknown')
    if (submitError.res && submitError.res.status === 404) {
      message = translate('submitErrors.systemNotFound')
    } else if (submitError.res && submitError.res.status === 409) {
      message = translate('submitErrors.systemAlreadyInUse')
    }

    return (
      <Alert
        severity='error'
        className={styles.error}
      >
        {message}
      </Alert>
    )
  }
  const userJS = user.toJS()

  const handleClickDelete = () => {
    setConfirmingDelete(true)
  }

  const confirmDeleteFile = () => {
    deleteUserLogo()
    setConfirmingDelete(false)
  }

  const onClickCancel = () => {
    hideModal()
  }

  const handleChangeFile = event => {
    setFileSelected(true)
    setUploadedFile(event.target.files[0])
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      BackdropProps={{
        classes: {
          root: styles.backdrop
        }
      }}
      PaperComponent={StyledPaper}
    >
      <div className={styles.modalContainer}>
        <div className={styles.header}>{translate('uploadFileModal.title')}</div>
        <div className={styles.content}>
          <Typography className={styles.currentLogoText}>
            {translate('uploadFileModal.currentLogo')}
          </Typography>
          <div className={styles.logoWrapper}>
            {userJS.logo && !fileSelected && (
              <img
                src={userJS.logo}
                alt='Logo'
                className={styles.userLogo}
              />
            )}
            {!userJS.logo && !fileSelected && (
              <div className={styles.noLogoWrapper}>{translate('uploadFileModal.noLogo')}</div>
            )}
            {fileSelected && (
              <img
                src={URL.createObjectURL(uploadedFile)}
                alt='Logo'
                className={styles.userLogo}
              />
            )}
          </div>
          <form onSubmit={handleClick}>
            <div className={styles.selectFileWrapper}>
              <label className={styles.selectFileLabel}>
                <input
                  type='file'
                  onChange={handleChangeFile}
                />
                {translate('uploadFileModal.uploadFile')}
              </label>
              {userJS.logo && (
                <div>
                  <Button
                    disableRipple
                    variant='outlined'
                    className={styles.deleteFileButton}
                    onClick={handleClickDelete}
                  >
                    {translate('uploadFileModal.deleteFile')}
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.buttonWrapper}>
              <LoadingButton
                type='submit'
                disableRipple
                className={styles.uploadButton}
                fullWidth
                loading={isSubmitting}
                variant='contained'
                loadingPosition='end'
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {translate('uploadFileModal.saveChanges')}
              </LoadingButton>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                fullWidth
                disableRipple
                variant='contained'
                className={styles.buttonCancelar}
                onClick={onClickCancel}
              >
                {translate('uploadFileModal.cancel')}
              </Button>
            </div>
          </form>
          {confirmingDelete && (
            <ConfirmationModal
              hideModal={() => setConfirmingDelete(false)}
              translate={translate}
              onConfirm={confirmDeleteFile}
              contentText={translate('uploadFileModal.deleteLogoConfirmationText')}
            />
          )}
          {error()}
        </div>
      </div>
    </Modal>
  )
}

UploadFileContainer.propTypes = {
  submitError: PropTypes.any,
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  showSnackbar,
  uploadUserLogo,
  deleteUserLogo
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(UploadFileContainer))
