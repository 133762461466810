import { createReducer } from 'utils'
import { fromJS } from 'immutable'

import { constants } from 'common/actions/systems'
import { RESET_ERROR } from 'common/actions/error'

export const initialState = fromJS({
  isSubmitting: false,
  error: null
})

export default createReducer(initialState, {
  [RESET_ERROR]: state => state.set('error', null),

  [constants.CREATE_REPORT_REQUEST.SUCCESS]: () => initialState
})
