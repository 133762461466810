import * as Routes from 'constants/Routes'
import { injectReducer } from 'common/reducers'

export default store => ({
  path: Routes.VISUALISATION,
  getComponent: async function (nextState, cb) {
    const [module, reducer] = await Promise.all([
      import('./containers/VisualisationContainer'),
      import('./reducers')
    ])

    injectReducer(store, { key: 'profile', reducer: reducer.default })

    cb(null, module.default)
  }
})
