export const deviceIdMask = [
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/,
  '-',
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/,
  '-',
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/,
  /[A-F0-9a-f]/
]
