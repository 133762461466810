import typeOfBuildingJSON from './typeOfBuilding'
import airtightnessJSON from './airTightness'
import antiquityJSON from './antiquity'
import occupancyResidentialJSON from './occupancyResidential'
import occupancyTertiaryJSON from './occupancyTertiary'
import typeBuildingTertiaryJSON from './typeBuildingTertiary'
import typeBuildingResidentialJSON from './typesBuildingResidential'
import typeOfSystemJSON from './typeOfSystem'
import ventilationResidentiaJSON from './ventilationResidential'
import ventilationTertiaryJSON from './ventilationTertiary'

export const typeOfBuilding = typeOfBuildingJSON
export const airTightness = airtightnessJSON
export const antiquity = antiquityJSON
export const occupancyResidential = occupancyResidentialJSON
export const occupancyTertiary = occupancyTertiaryJSON
export const typeBuildingTertiary = typeBuildingTertiaryJSON
export const typeBuildingResidential = typeBuildingResidentialJSON
export const typeOfSystem = typeOfSystemJSON
export const ventilationResidential = ventilationResidentiaJSON
export const ventilationTertiary = ventilationTertiaryJSON
