import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Transition } from 'react-transition-group'
import cx from 'classnames'
import styles from './MajorLoader.module.scss'

import { getIsLoaderVisible } from 'common/selectors/loader'
import CircularProgress from '@material-ui/core/CircularProgress'

const Fade = ({ in: inProp, duration = 300, children }) => (
  <Transition
    in={inProp}
    timeout={duration}
  >
    {state => (
      <div
        className={cx(styles.fade, {
          [styles[`fade--${state}`]]: state
        })}
      >
        {children}
      </div>
    )}
  </Transition>
)

Fade.propTypes = {
  in: PropTypes.bool,
  duration: PropTypes.number,
  children: PropTypes.node
}

const MajorLoader = ({ instant, isVisible }) => {
  if (instant) {
    return (
      <div className={styles.component}>
        <div className={styles.loader}>
          <CircularProgress
            variant='indeterminate'
            size={30}
            className={styles.circle}
            thickness={4}
            disableShrink
            value={100}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.component}>
      <Fade in={isVisible}>
        <div className={styles.loader}>
          <CircularProgress
            className={styles.circle}
            variant='indeterminate'
            size={20}
            thickness={4}
            value={100}
          />
        </div>
      </Fade>
    </div>
  )
}

MajorLoader.propTypes = {
  instant: PropTypes.bool,
  isVisible: PropTypes.bool
}

const mapStateToProps = state => ({
  isVisible: getIsLoaderVisible(state)
})

const mapActionsToProps = null

export default connect(mapStateToProps, mapActionsToProps)(MajorLoader)
