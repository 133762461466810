import { createReducer } from 'utils'
import { fromJS } from 'immutable'
import { SHOW_MODAL, HIDE_MODAL } from '../actions/modal'

export const initialState = fromJS({
  type: null,
  props: null
})

export default createReducer(initialState, {
  [SHOW_MODAL]: (state, { payload: { type, props } }) => {
    return state.set('type', type).set('props', props)
  },
  [HIDE_MODAL]: () => initialState
})
