import { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { transferDevicesUser } from 'common/actions/user'
import { Modal } from '@material-ui/core'
import { getUser } from 'common/selectors/user'

import TransferSystemModalForm from '../components/TranferSystemModalForm'
import { resetError } from 'common/actions/error'
import { hideModal } from 'common/actions/modal'
import styles from './TransferSystemModalContainer.module.scss'
import { getError, getIsSubmitting } from '../selectors'
import ConfirmationModal from '../../ConfirmationModal'
import cx from 'classnames'

const TransferSystemModalContainer = props => {
  const [open] = useState(true)
  const [tranlatedText, setTranslatedText] = useState()
  const [confirmingTransfer, setConfirmingTransfer] = useState(false)
  const [datas, setDatas] = useState({})
  const { hideModal, resetError, systems, user, transferDevicesUser, translate } = props
  const systemsByGroup = _.groupBy(systems, 'group')
  const handleSubmit = () => {
    setConfirmingTransfer(false)
    transferDevicesUser(datas)
  }

  let text
  const ConfirmTransfer = async data => {
    let jsonData = {}
    jsonData.deviceIds = data.selectedDevices
    jsonData.email = data.email.trim().toLowerCase()
    jsonData.customerId = user.toJS()._id
    text = (
      <p>
        {translate('confirmTransferFirstText')} <b>{data.selectedDevices.length}</b>{' '}
        {data.selectedDevices.length > 1
          ? translate('confirmTransferSecondText')
          : translate('confirmTransferThirdText')}{' '}
        <b>{data.email}</b> <br /> <br />
        {translate('confirmTransferFourthText')} <br /> <br />
        <p className={styles.textSize}>
          <img
            src='../images/info.svg'
            className={cx('img_info', styles.infoIcon)}
            alt=''
          />{' '}
          {translate('infoTextTransfer')}
        </p>{' '}
      </p>
    )
    setTranslatedText(text)
    setDatas(jsonData)
    setConfirmingTransfer(true)
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <TransferSystemModalForm
            {...props}
            systems={systems}
            systemsByGroup={systemsByGroup}
            hideModal={hideModal}
            onSubmit={ConfirmTransfer}
          />
          {confirmingTransfer && (
            <ConfirmationModal
              hideModal={() => setConfirmingTransfer(false)}
              translate={translate}
              onConfirm={handleSubmit}
              contentText={tranlatedText}
              buttonName={translate('transferButton')}
              buttonStyleBlue={'true'}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

TransferSystemModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  transferDevicesUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  transferDevicesUser,
  resetError,
  hideModal
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(TransferSystemModalContainer)
)
