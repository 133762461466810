import { sortDevicesOptions, sortGroupsOptions } from 'constants/SortOptions'
import * as storage from './storage'

export const setUserChannel = channel => {
  storage.setItem('channel', channel)
}

export const setHomeActiveTabData = activeTab => {
  storage.setItem('homeActiveTab', activeTab)
}

export const setHomeSortDevicesOption = option => {
  storage.setItem('sortDevicesOption', option)
}

export const setHomeSortGroupsOption = option => {
  storage.setItem('sortGroupsOption', option)
}

export const setUserMeasurementSystem = measurementSystem => {
  storage.setItem('measurementSystem', measurementSystem)
}

export const setUserCookieConsent = value => {
  storage.setItem('cookieConsent', value)
}

export const getUserChannel = () => {
  const data = storage.getItem('channel')

  if (!data) {
    return false
  }

  return data
}

export const getHomeActiveTabData = isPremium => {
  const data = storage.getItem('homeActiveTab')
  const isMobile = window.innerWidth <= 768
  if (isMobile) {
    return 0
  }
  if (!isPremium && data === 1) {
    return 2
  }

  if (!data) {
    return false
  }

  return data
}

export const getHomeSortDevicesOption = () => {
  const data = storage.getItem('sortDevicesOption')

  if (sortDevicesOptions.includes(data)) {
    return data
  }
  return sortDevicesOptions[0]
}

export const getHomeSortGroupsOption = () => {
  const data = storage.getItem('sortGroupsOption')

  if (sortGroupsOptions.includes(data)) {
    return data
  }

  return sortGroupsOptions[0]
}

export const getUserMeasurementSystem = () => {
  const data = storage.getItem('measurementSystem')

  if (!data) {
    return 'mS'
  }

  return data
}

export const getUserCookieConsent = () => {
  const data = storage.getItem('cookieConsent')

  if (data === undefined || data === null) {
    return true
  }

  return data
}
