import { createRoot } from 'react-dom/client'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

import 'react-tabs/style/react-tabs.css'
import 'rc-tooltip/assets/bootstrap_white.css'

import { authUser } from 'common/actions/auth'
import { initLocalize } from 'common/actions/localize'
import configureStore from 'utils/configureStore'
import makeRootSaga from 'common/sagas'
import Root from 'common/components/Root'

import Amplify from 'aws-amplify'
import { AWS_COGNITO_REGION, AWS_COGNITO_USER_POOL_ID, AWS_COGNITO_WEB_CLIENT_ID } from './config'

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: AWS_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: AWS_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: AWS_COGNITO_WEB_CLIENT_ID,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: 'true',
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH'
  }
})

// Make taps on links and buttons work fast on mobiles
// FastClick.attach(document.body)

const store = configureStore({}, browserHistory)
const history = syncHistoryWithStore(browserHistory, store)
store.runSaga(makeRootSaga())

store.dispatch(authUser())
store.dispatch(initLocalize())

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(
  <Root
    store={store}
    history={history}
  />
)

export { store }
