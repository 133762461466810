import { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  TextField,
  RadioGroup,
  Radio,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  ListItem,
  FormControlLabel,
  Collapse,
  ListItemText
} from '@material-ui/core'
import { LoadingButton } from '@mui/lab'

import styles from './DownloadWellStaticDataForm.module.scss'
import * as SystemUtils from 'utils/system'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary': {
      color: 'rgba(255,255,255)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-1px)',
      width: '21px',
      height: '21px'
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(33,33,33, 0.4)'
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  },
  MuiTimePicker: {
    styleOverrides: {
      root: {
        backgroundColor: blue[600]
      }
    }
  }
}))

const DownloadWellStaticDataForm = props => {
  const { onSubmit, translate, systems, systemsByGroup } = props

  let { formState, handleSubmit, register, errors, clearErrors } = useForm()

  const { isSubmitting } = formState
  const classes = useStyles()

  const [open, setOpen] = useState({})
  const [selectedDeviceField, setSelectedDeviceField] = useState()
  const [systemsGroupState, setSystemsGroupState] = useState(systemsByGroup)

  const [dataState, setDataState] = useState({})

  const handleChange = event => {
    if (event.target.type === 'radio') {
      clearErrors('selectedDevices')
    }
  }

  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const handleChangeSelectedDevice = event => {
    setSelectedDeviceField(event.target.value)
    clearErrors('selectedDevice')
  }

  const checkInputs = () => {
    let data = {}

    let types = []
    let system = systems.filter(s => {
      return s._id === selectedDeviceField
    })[0]
    const sortedData = SystemUtils.getDataSorted(system)
    sortedData.map(d => {
      if (types.indexOf(d.type) === -1) types.push(d.type)
      return null
    })

    data.params = types
    data.testDate = system.testDate
    data.deviceId = selectedDeviceField
    setDataState(data)
  }

  const onClickDownload = () => {
    checkInputs()
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>
          {translate('downloadWellStaticDataModal.downloadStatic')}
        </Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div>
              <div className={styles.firstPage}>
                <Typography className={styles.subheaderDevicesSelectedStatic}>
                  {translate('downloadWellStaticDataModal.navDevices')}
                </Typography>
                <div>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='search'
                    onChange={filterExpressionChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                    id='searchDevice'
                    name='searchDevice'
                    placeholder={translate('searchDevice')}
                    className={cx(styles.input, classes.input, {
                      [styles.hasError]: errors.reportName
                    })}
                  />
                  <Paper
                    className={styles.devicesListPaper}
                    classes={{ root: classes.paper }}
                  >
                    <List
                      disableTouchRipple
                      className={styles.devicesList}
                    >
                      <FormControl component='fieldset'>
                        <RadioGroup onChange={handleChange}>
                          {_.map(systemsGroupState, function (systems, group) {
                            systems = _.groupBy(systems, 'micaType')
                            return (
                              <div className={styles.noGroupWrapper}>
                                {group !== 'undefined' && (
                                  <div>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.groupListItem}
                                    >
                                      {!open[group] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(group)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(group)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={group}
                                        className={styles.listItemText}
                                      />
                                    </ListItem>
                                  </div>
                                )}
                                {group === 'undefined' && (
                                  /* Desplegamos Por Tipos, micas sin Grupo definido */
                                  <div>
                                    {_.map(systems, function (systems, type) {
                                      return (
                                        <div className={styles.typeRowWrapper}>
                                          <ListItem
                                            button
                                            disableTouchRippleindex
                                            className={styles.typeListItem}
                                          >
                                            {!open[type] ? (
                                              <ExpandLess
                                                onClick={() => handleClick(type)}
                                                className={styles.expandIcon}
                                              />
                                            ) : (
                                              <ExpandMore
                                                onClick={() => handleClick(type)}
                                                className={styles.expandIcon}
                                              />
                                            )}
                                            <ListItemText
                                              primary={translate(type)}
                                              className={styles.micaTypeItemText}
                                            />
                                          </ListItem>
                                          <Collapse
                                            in={!open[type]}
                                            timeout='auto'
                                            unmountOnExit
                                          >
                                            {systems.map((system, index) => {
                                              return (
                                                <div
                                                  className={styles.micaItem}
                                                  key={system._id}
                                                >
                                                  <FormControlLabel
                                                    onKeyPress={e => {
                                                      e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    className={styles.checkboxForm}
                                                    value={system._id}
                                                    onChange={handleChangeSelectedDevice}
                                                    control={
                                                      <Radio
                                                        className={cx(
                                                          styles.checkbox,
                                                          styles.radioItem
                                                        )}
                                                        classes={{ checked: styles.checkedBox }}
                                                        inputRef={register()}
                                                        disableRipple
                                                        checked={system._id === selectedDeviceField}
                                                        name={'selectedDevices'}
                                                        value={system._id}
                                                      />
                                                    }
                                                    label={system.name}
                                                  />
                                                </div>
                                              )
                                            })}
                                          </Collapse>
                                        </div>
                                      )
                                    })}
                                  </div>
                                )}
                                {group !== 'undefined' && (
                                  <Collapse
                                    in={!open[group]}
                                    timeout='auto'
                                    unmountOnExit
                                    classes={{
                                      wrapper: classes.wrapper
                                    }}
                                  >
                                    {_.map(systems, function (systems, type) {
                                      return (
                                        <div className={styles.typeRowWrapper}>
                                          <ListItem
                                            button
                                            disableTouchRipple
                                            className={styles.typeListItem}
                                          >
                                            {!open[type] ? (
                                              <ExpandLess
                                                onClick={() => handleClick(type)}
                                                className={styles.expandIcon}
                                              />
                                            ) : (
                                              <ExpandMore
                                                onClick={() => handleClick(type)}
                                                className={styles.expandIcon}
                                              />
                                            )}
                                            <ListItemText
                                              primary={type}
                                              className={styles.micaTypeItemText}
                                            />
                                          </ListItem>
                                          <Collapse
                                            in={!open[type]}
                                            timeout='auto'
                                            unmountOnExit
                                          >
                                            {systems.map((system, index) => {
                                              return (
                                                <div
                                                  className={styles.micaItem}
                                                  key={system._id}
                                                >
                                                  <FormControlLabel
                                                    onKeyPress={e => {
                                                      e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    className={styles.checkboxForm}
                                                    value={system._id}
                                                    onChange={handleChangeSelectedDevice}
                                                    control={
                                                      <Radio
                                                        className={cx(
                                                          styles.checkbox,
                                                          styles.radioItem
                                                        )}
                                                        classes={{ checked: styles.checkedBox }}
                                                        inputRef={register()}
                                                        disableRipple
                                                        error={errors.selectedDevices}
                                                        checked={system._id === selectedDeviceField}
                                                        name={'selectedDevices'}
                                                        value={system._id}
                                                      />
                                                    }
                                                    label={system.name}
                                                  />
                                                </div>
                                              )
                                            })}
                                          </Collapse>
                                        </div>
                                      )
                                    })}
                                  </Collapse>
                                )}
                              </div>
                            )
                          })}
                        </RadioGroup>
                      </FormControl>
                    </List>
                  </Paper>
                  <div className={styles.wrapperError}>
                    {errors.selectedDevices && (
                      <div className={styles.error}>
                        <small>{translate('validation.anyDeviceRequired')}</small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    type='submit'
                    fullWidth
                    loading={isSubmitting}
                    loadingPosition='end'
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickDownload}
                  >
                    {translate('downloadData')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

DownloadWellStaticDataForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(DownloadWellStaticDataForm)
