import PropTypes from 'prop-types'
import styles from './AuthLayout.module.scss'

import AuxHeader from '../../AuxHeader/AuxHeader'
import { InbiotLogo } from '../../../../icons'
import { Icon } from '@material-ui/core'
import Footer from '../../Footer'

const AuthLayout = props => {
  return (
    <div className={styles.component}>
      <AuxHeader isLoggedIn={false} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.inbiotLogo}>
            <Icon className={styles.logo}>
              <img
                className={styles.logoImage}
                src={InbiotLogo}
                alt=''
              />
            </Icon>
          </div>
          {props.children}
        </div>
      </div>
      <Footer />
    </div>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node
}

export default AuthLayout
