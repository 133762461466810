import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './AppLayout.module.scss'

import { getIsLoaded as getIsUserLoaded } from 'common/selectors/user'

import MajorLoader from 'modules/MajorLoader'
import ModalRoot from 'modules/ModalRoot'
import SnackbarRoot from 'modules/SnackbarRoot'

const AppLayout = props => {
  if (!props.isUserLoaded) {
    return (
      <div className={styles.component}>
        <MajorLoader instant />
      </div>
    )
  }

  return (
    <div className={styles.component}>
      {props.children}
      <MajorLoader />
      <SnackbarRoot />
      <ModalRoot />
    </div>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isUserLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isUserLoaded: getIsUserLoaded(state)
})

const mapActionsToProps = {
  // Empty
}

export default connect(mapStateToProps, mapActionsToProps)(AppLayout)
