import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Modal } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import AddSystemModalForm from '../components/AddSystemModalForm/AddSystemModalForm'
import styles from './AddSystemContainer.module.scss'
import { getIsSubmitting, getError } from '../selectors'
import { getUser } from 'common/selectors/user'
import { addSystemToUser } from 'common/actions/user'
import { hideModal } from 'common/actions/modal'
import { resetError } from 'common/actions/error'

const AddSystemContainer = props => {
  const [open] = useState(true)

  const { hideModal, addSystemToUser, resetError } = props

  const handleSubmit = async data => {
    data.macAddress = data.macAddress.toUpperCase()
    addSystemToUser(data)
  }

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      fontFamily: 'Roboto',
      verticalAlign: 'top',
      maxWidth: '500px'
    }
  })

  useEffect(() => {
    resetError()
  }, [resetError])
  const { user } = props
  const initialValues = {
    country: user.get('country')
  }
  return (
    <>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
        BackdropProps={{
          classes: {
            root: styles.backdrop
          }
        }}
        PaperComponent={StyledPaper}
      >
        <div className={styles.content}>
          <AddSystemModalForm
            {...props}
            initialValues={initialValues}
            hideModal={hideModal}
            onSubmit={handleSubmit}
          />
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  addSystemToUser,
  hideModal,
  resetError
}

AddSystemContainer.propTypes = {
  user: PropTypes.instanceOf(Map),
  hideModal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapActionsToProps)(AddSystemContainer)
