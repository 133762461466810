export const API_URL = process.env.REACT_APP_API_URL
export const URL = process.env.REACT_APP_URL
export const AUTH_STORE_NAMESPACE = 'sg-token-client'
export const LOCALIZE_STORE_NAMESPACE = 'localize'
export const SITE_KEY = process.env.RECAPTCHA_SITE_KEY
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION
export const AWS_COGNITO_USER_POOL_ID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID
export const AWS_COGNITO_WEB_CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_WEB_CLIENT_ID
export const LOGROCKET_ID = process.env.REACT_APP_LOGROCKET_ID
export const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY
